import React from 'react';
import * as S from '../../theme/style';
import { SurveyRankingListType } from '../ComponentCommonType';
import { colors } from '../../theme/color';
import { styled } from 'styled-components';



export const SurveyRankingList = ({ //설문 라디오 선택(단일 및 다중 선택)
    radioArray,
    radioValue,
    selValue,
    setSelSurvey,
    maxSelect,
    questionId,
    title,
    choiceId,
    answerList,
} : SurveyRankingListType) => {

    const [isSel, setIsSel] = React.useState(false);
    const [rank, setRank] = React.useState(0);

    /** 항목 선택 handler (단일 및 다중 선택) */
    const selSurbeyHandler = () => {
        if(maxSelect){
            console.log(radioValue);
            setSelSurvey(radioValue,questionId,choiceId,false,true,maxSelect);
        }
    }

    React.useEffect(()=>{
        const index = answerList?.findIndex(el => el.choiceId === choiceId);

        if(index !== -1 && (index || index === 0)){
            setIsSel(true);
            setRank(index+1);
        }
        else{
            setIsSel(false);
            setRank(0);
        }
        // if(Array.isArray(selValue)){
        //     setIsSel(selValue.includes(radioValue));
        // }
    },[selValue,answerList])
    
    return(
        <div>
            <S.CustomFlex
                br={1}
                bw={isSel? 0.2 : 0.1}
                bc={isSel ? colors.MAIN_COLOR : colors.BORDER_COLOR2}
                pt={isSel ? 1.4 : 1.5} pb={isSel ? 1.4 : 1.5} pl={isSel ? 2.4 : 2.5} pr={isSel ? 2.4 : 2.5}
                flex_direction={'row'}
                justify_content={'space-between'}
                align_items={'center'}
                mb={1}
                onClick={selSurbeyHandler}
            >
                <S.CustomSpanText 
                    fs={1.5}
                    fc={isSel ? colors.MAIN_COLOR : colors.FONT_COLOR3}
                    fw={isSel ? 'Bold' : 'Regular'}
                >
                    {title}
                </S.CustomSpanText>

                <S.CustomDiv>
                    <S.CustomFlex
                        background={isSel ? 'transparent linear-gradient(180deg, #926BFF 0%, #4737DF 100%) 0% 0% no-repeat padding-box' : undefined}
                        bgcolor={!isSel ? colors.SUB_BACKGROUND_COLOR2 : undefined}
                        width={2.8}
                        height={2.8}
                        br={999}
                        justify_content='center'
                        align_items='center'
                    >   

                        {isSel ?
                            <S.CustomSpanText
                                fs={1.6}
                                fc={colors.WHITE}
                                fw={'Medium'}
                            >
                                {rank}
                            </S.CustomSpanText>
                        :
                            <S.CustomDiv 
                                bgcolor={colors.WHITE}
                                br={999}
                                width={1.2}
                                height={1.2}
                            />
                        }
                        
                    </S.CustomFlex>
                </S.CustomDiv>
            </S.CustomFlex>
        </div>
    )


}
