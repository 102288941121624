import React from 'react';
import { Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { SurveyMainList } from '../screen/SurveyMainList';
import { SurveyDetail } from '../screen/SurveyDetail';
import { SurveyProgress } from '../screen/progress/SurveyProgress';
import { SurveyResult } from '../screen/SurveyResult';
import { SurveyQuickResult } from '../screen/SurveyQuickResult';
import { PointList } from '../screen/PointList';
import { SurveyIntro } from '../screen/SurveyIntro';
import { useUserInfoStore } from '../store';
import { MemberAuth } from '../screen/auth/MemberAuth';


export const Router = () => {

    const basename: string = `${process.env.REACT_APP_HOST_VER}`; //BASE URL;

    return (
      <BrowserRouter basename={basename}>
        <Routes>
          <Route path={'/'} Component={MemberAuth} />
          <Route path={'/SurveyList'} Component={SurveyMainList} />
          <Route path={'/Intro'} Component={SurveyIntro} />
          <Route path={'/SurveyDetail'} Component={SurveyDetail} />
          <Route path={'/SurveyProgress'} Component={SurveyProgress} />
          <Route path={'/SurveyResult'} Component={SurveyResult} />
          <Route path={'/SurveyQuickResult'} Component={SurveyQuickResult} />
          <Route path={'/PointList'} Component={PointList} />
        </Routes>
      </BrowserRouter>
    )
}