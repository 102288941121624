import React from 'react';
import * as S from '../../theme/style';
import Sheet from 'react-modal-sheet';
import { colors } from '../../theme/color';
import { SurveySelectBoxType } from '../ComponentCommonType';

import '../../theme/custom.css';



export const SurveySelectBox = ({ //설문 select box (bottom modal 생성)
    selectListHandler,
    selectList,
    quetionItemInfo,
    answerList
}:SurveySelectBoxType) => {

    const [isOpen, setOpen] = React.useState(false);

    const [selValue, setSelValue] = React.useState('');

    React.useEffect(()=>{
        if(answerList){
            if(answerList.length > 0){
                setSelValue(answerList[0].value);
            }
            else{
                setSelValue('');
            }            
        }
    },[answerList])

    return(
        <>
        <S.CustomFlex
            br={1}
            bc={selValue === '' ? colors.BORDER_COLOR2 : colors.MAIN_COLOR}
            bw={0.1}
            pt={2} pb={2} pl={3} pr={3}
            flex_direction='row'
            justify_content='space-between'
            align_items='center'
            bgcolor={selValue === '' ? colors.WHITE : colors.SUB_BACKGROUND_COLOR5}
            onClick={()=>{setOpen(true)}}
        >
            <S.CustomSpanText
                fs={1.5}
                fc={selValue === '' ? colors.FONT_COLOR3 : colors.MAIN_COLOR}
            >
                {selValue === '' ? '선택하세요' : selValue}
            </S.CustomSpanText>
            <S.CustomImg 
                width={2}
                src={require('../../assets/img/arrow1.png')}
            />
        </S.CustomFlex>
        <Sheet isOpen={isOpen} onClose={() => setOpen(false)}>
            <Sheet.Container>
            <Sheet.Header>
                <S.CustomDiv
                        width={'100%'}
                        // height={2}
                        position='absolute'
                        top={-7}
                        left={0}
                        onClick={()=>{setOpen(false)}}
                    >
                        {/* <img src={require('../../assets/img/ico_close2.png')} /> */}
                        <S.CustomImg 
                            width={4}
                            src={require('../../assets/img/ico_close2.png')}
                        />
                    </S.CustomDiv>
            </Sheet.Header>
            <Sheet.Content disableDrag={true}>
                <S.CustomDiv
                    mt={4}
                >
                    <S.CustomDiv
                        mb={2.5}
                    >
                        <Sheet.Scroller >
                            <S.CustomDiv
                                height={20}
                            >
                                {selectList.map((item,index) => {
                                    return(
                                        <S.CustomDiv
                                            key={index}
                                            mb={4}
                                            onClick={()=>{
                                                selectListHandler(item.value,item.choiceId,quetionItemInfo.questionId);
                                                setOpen(false);
                                            }} 
                                        >
                                            <S.CustomPText
                                                key={index} 
                                                text_align='center'
                                                fs={1.5}
                                                fc={selValue === item.value ? colors.MAIN_COLOR : colors.FONT_COLOR3}
                                            >
                                                {item.value}
                                            </S.CustomPText>
                                        </S.CustomDiv>
                                    )
                                })}
                            </S.CustomDiv>
                        </Sheet.Scroller>
                    </S.CustomDiv>
                    {/* <S.CustomDiv
                        position={'absolute'}
                        bottom={0}
                        width={'100%'}
                        height={'50%'}
                        background={'linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));'}
                        style={{pointerEvents: 'none'}}
                    /> */}
                </S.CustomDiv>
            </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop
                onTap={()=>{
                    setOpen(false);
                }}
            >
            </Sheet.Backdrop>
        </Sheet>
        </>
    )
}