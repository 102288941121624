import React from 'react';
import * as S from '../theme/style';
import { getFontSize } from '../utils/getSize';
import { colors } from '../theme/color';
import { useNavigate } from 'react-router';
import { IntroTitle } from '../component/List/IntroTitle';
import { IntroContents } from '../component/List/IntroContents';
import { useUserInfoStore } from '../store';


// import Dimensions from 'react-dimensions';

export const SurveyIntro = () => {


    const {memberId, token,isNewMember} = useUserInfoStore(state => state);

    const navigation = useNavigate();


    const goList = () => {
        navigation('SurveyList');
    }

    return(
        <S.CustomDiv>
            <S.CustomImg 
                width={'100%'}
                src={require('../assets/img/banner01.jpg')}
            />
            <S.ContainerXl>
                <S.CustomDiv
                    pt={2.5} pb={2.5} mb={10}
                >   
                    <IntroTitle ment={'돈 버는 설문이란?'} />
                    <S.CustomDiv mt={2}>
                        <S.CustomFlex
                            flex_direction='row'
                        >
                            <S.CustomDiv
                                flex={0.3}
                            >
                                <S.CustomDiv
                                    mt={0.6} 
                                    width={0.5}
                                    height={0.5}
                                    br={999}
                                    bgcolor={colors.MAIN_COLOR}
                                />
                            </S.CustomDiv>
                            <S.CustomDiv
                                flex={8}
                            >
                                <S.CustomPText
                                    fs={1.5}
                                    fc={colors.FONT_COLOR2}
                                    fw={'Light'}
                                >
                                돈버는설문은 설문 응답과 이벤트 및 광고를 확인만 해도 <S.CustomSpanText fc={colors.MAIN_COLOR} fw={'Medium'}>T플러스포인트를 적립해 드리는 혜택모음 서비스</S.CustomSpanText>입니다. (이벤트응모나 설문참여를 통해 더 많은 포인트를 받으실 수 있어요)
                                </S.CustomPText>
                            </S.CustomDiv>
                        </S.CustomFlex>
                    </S.CustomDiv>
                    <IntroContents ment={'적립한 T플러스포인트는 T맴버십 제휴사 사용시 다양하게 사용하실 수 있습니다.'} />
                    <IntroContents ment={'돈버는설문 서비스에 대한 운영 및 관리는 제휴사인 아태인구연구원을 통해 제공됩니다.'} />

                    <S.MarginCom
                        mb={5}
                    />

                    <IntroTitle ment={'T플러스 포인트란?'} />
                    <S.CustomDiv mt={2}>
                        <S.CustomFlex
                            flex_direction='row'
                        >
                            <S.CustomDiv
                                flex={0.3}
                            >
                                <S.CustomDiv
                                    mt={0.6} 
                                    width={0.5}
                                    height={0.5}
                                    br={999}
                                    bgcolor={colors.MAIN_COLOR}
                                />
                            </S.CustomDiv>
                            <S.CustomDiv
                                flex={8}
                            >
                                <S.CustomPText
                                    fs={1.5}
                                    fc={colors.FONT_COLOR2}
                                    fw={'Light'}
                                >
                                T플러스포인트는 T멤버십 앱 또는 오프라인에서 <S.CustomSpanText fc={colors.MAIN_COLOR}>T멤버십 제휴 브랜드</S.CustomSpanText>를 이용할 때 사용하실 수 있습니다.
                                </S.CustomPText>
                            </S.CustomDiv>
                        </S.CustomFlex>
                    </S.CustomDiv>
                    <IntroContents ment={'T멤버십에 가입한 고객 대상 적립 및 사용이 가능하며, 5년간 유효합니다.'} />
                    <IntroContents ment={'SKT 이동전화를 해지하면 T멤버십 회원이 탈퇴 되어 T플러스포인트를 사용할 수 없습니다. (1개월 내 T멤버십회원 또는 T우주Lite로 재가입시 소멸을 복원할 수 있습니다.)'} />
                    <S.CustomDiv mt={2}>
                        <S.CustomFlex
                            flex_direction='row'
                        >
                            <S.CustomDiv
                                flex={0.3}
                            >
                                <S.CustomDiv
                                    mt={0.6} 
                                    width={0.5}
                                    height={0.5}
                                    br={999}
                                    bgcolor={colors.MAIN_COLOR}
                                />
                            </S.CustomDiv>
                            <S.CustomDiv
                                flex={8}
                            >
                                <S.CustomPText
                                    fs={1.5}
                                    fc={colors.FONT_COLOR2}
                                    fw={'Light'}
                                >
                                    기타 자세한 사항은 <S.CustomSpanText fc={colors.FONT_COLOR2} fw={'Medium'}>T멤버십 앱 또는 고객센터(114)</S.CustomSpanText>를 통해 확인 가능합니다.
                                </S.CustomPText>
                            </S.CustomDiv>
                        </S.CustomFlex>
                    </S.CustomDiv>
                </S.CustomDiv>
                
            </S.ContainerXl>
            <S.CustomFlex
                bgcolor={colors.MAIN_COLOR}
                width={'100%'}
                // height={6}
                pt={2.5}
                pb={2.5}
                position={'fixed'}
                bottom={0}
                justify_content={'center'}
                align_items={'center'}
                onClick={()=>{goList();}}
            >
                <S.CustomSpanText
                    fs={1.8}
                    fw={'Bold'}
                    fc={colors.WHITE}
                >
                    참여하기
                </S.CustomSpanText>
            </S.CustomFlex>
        </S.CustomDiv>
    )
}


