import React from 'react'
import * as S from '../theme/style'
import { colors } from '../theme/color'
import { NumberComma } from '../utils/func'
import { useLocation, useNavigate } from 'react-router'
import { useUserInfoStore } from '../store'
import { useGetMutation } from '../hook/ReactQueryHook'
import { FinishItemType, QuestionListType } from './CommonScreenType'

export const SurveyResult = () => {
  const navigation = useNavigate()
  const location = useLocation()

  const { token, memberId, refreshToken, updateUserInfo } = useUserInfoStore((state) => state)
  const { pageurl } = location.state
  const getSurveyQuestionMutation = useGetMutation(
    'getSurveyQuestion',
    `${pageurl}`
  ) //설문정보 가져오기

  const [finishInfo, setFinishInfo] = React.useState<FinishItemType>()

  const getSurveyQuestionHandler = async () => {
    const params = {
      postData: {},
      headConfig: {
        memberId: memberId,
        Authorization: `Bearer ${token}`,
        refreshToken: `Bearer ${refreshToken}`,
      },
    }

    await getSurveyQuestionMutation
        .mutateAsync(params)
        .then(res => { 
            const { data, code, msg, refreshInfo } = res;

            if (refreshInfo) {
              updateUserInfo({
                accessTime: new Date(),
                memberId: refreshInfo.memberId,
                token: refreshInfo.tokenInfo.accessToken,
                refreshToken: refreshInfo.tokenInfo.refreshToken,
                isNewMember: refreshInfo.isNewMember,
              })
            }
            if (code === 'SUCCESS') {
              console.log(data.page);
              setFinishInfo(data.page)
            } else {
            }

        })
      .catch((err) => {
        if (err.response.status === 400) {
          console.log('is 400 error')
          return false
        }
      })

    
  }

  const moveQuickResult = () => {
    //퀵서베이 결과확인
    navigation(`/SurveyQuickResult?surveyId=${finishInfo?.survey.surveyId}`)
  }

  React.useEffect(() => {
    if (token === '' || memberId === '') {
      console.log('is error!')
    } else {
      getSurveyQuestionHandler()
    }
  }, [])

  return (
    <S.ContainerXl>
      <S.CustomFlex height={'100%'} mb={8}>
        <S.CustomFlex
          flex_direction="row"
          justify_content="flex-end"
          pt={2}
          style={{ alignSelf: 'flex-end' }}
        >
          <S.CustomFlex
            br={15}
            pt={1}
            pb={1}
            pr={2}
            pl={2}
            bgcolor={colors.SUB_BACKGROUND_COLOR_GRAY_BLUE}
            flex_direction="row"
            align_items="center"
          >
            <S.CustomImg
              width={4}
              src={require('../assets/img/ico_point.png')}
            />
            <S.CustomPText fs={1.3} fc={colors.FONT_COLOR1} fw={'Medium'}>
              포인트 확인
            </S.CustomPText>
            <S.CustomImg
              ml={0.5}
              width={1.5}
              src={require('../assets/img/ico_arrow.png')}
            />
          </S.CustomFlex>
        </S.CustomFlex>
        <S.CustomFlex
          flex={15}
          // height={'100%'}
          justify_content="center"
          align_items="center"
          width={'100%'}
          mt={4}
        >
          <S.CustomImg
            width={'100%'}
            src={require('../assets/img/gift_box.png')}
            style={{ maxWidth: '30rem' }}
          />
          <S.MarginCom mt={3} />
          <S.CustomDiv>
            <S.CustomPText fs={2.2} fc={colors.FONT_COLOR1} fw={'Medium'}>
              설문에 참여해 주셔서 감사합니다.
            </S.CustomPText>
            {finishInfo?.survey?.basicRewardPoint && (
              <S.CustomDiv mt={1}>
                <S.CustomSpanText
                  fs={1.8}
                  fc={colors.SKY_BLUE_COLOR}
                  fw={'Bold'}
                >
                  {NumberComma(finishInfo?.survey.basicRewardPoint)}포인트 적립
                </S.CustomSpanText>
                <S.CustomSpanText fs={1.8} fc={colors.FONT_COLOR3}>
                  되었습니다.
                </S.CustomSpanText>
              </S.CustomDiv>
            )}
          </S.CustomDiv>
          <S.CustomFlex
            br={1}
            mt={4}
            pt={1.5}
            pb={1.5}
            bgcolor={colors.WHITE}
            bw={0.1}
            bc={colors.BORDER_COLOR2}
            width={'99%'}
            justify_content="center"
            align_items="center"
            onClick={() => {
              navigation('/SurveyList', {replace:true})
            }}
          >
            <S.CustomSpanText fs={1.7} fc={colors.FONT_COLOR1} fw={'Medium'}>
              확인
            </S.CustomSpanText>
          </S.CustomFlex>
          {finishInfo?.survey.type === 'POLL' && (
            <S.CustomFlex
              br={1}
              mt={1}
              pt={1.5}
              pb={1.5}
              bgcolor={colors.MAIN_COLOR}
              bw={0.1}
              bc={colors.MAIN_COLOR}
              width={'99%'}
              justify_content="center"
              align_items="center"
              onClick={() => {
                moveQuickResult()
              }}
            >
              <S.CustomSpanText fs={1.7} fc={colors.WHITE} fw={'Medium'}>
                결과 확인하기
              </S.CustomSpanText>
            </S.CustomFlex>
          )}
        </S.CustomFlex>
      </S.CustomFlex>
    </S.ContainerXl>
  )
}
