import React from 'react';
import * as S from '../../theme/style';
import { SurveyStarSelectType } from '../ComponentCommonType';
import { colors } from '../../theme/color';


export const SurveyStarSelect = ({
    selStarValue,
    selectStarHandler,
    starValue,
    questionId,
    perList,
    title,
    choiceId,
}:SurveyStarSelectType) => {


    return(
        <S.CustomFlex
            flex={1}
            align_items='center'
            onClick={()=>{
                if(selectStarHandler && starValue && questionId && choiceId)selectStarHandler(starValue,questionId,choiceId,false,false);
            }}
        >
            <S.CustomDiv
                width={'100%'}
            >
                <S.CustomImg 
                    width={'100%'}
                    style={{maxWidth:'6.7rem'}}
                    src={selStarValue === starValue? require(`../../assets/img/score1_on.png`) : require(`../../assets/img/score1.png`)}
                />
                <S.CustomPText
                    mt={1}
                    text_align='center'
                    fs={1.5}
                    fc={selStarValue === starValue ? colors.SKY_BLUE_COLOR : colors.FONT_COLOR2}
                    fw={selStarValue === starValue ? 'Bold' : 'Regular'}
                >
                    {title}
                </S.CustomPText>

                {(perList && perList.length > 0) &&
                    <S.CustomPText
                        mt={0.2}
                        text_align={'center'}
                        fs={1.3}
                        fw={'Light'}
                        fc={selStarValue === starValue ? colors.SKY_BLUE_COLOR : colors.FONT_COLOR2}
                    >
                        ({perList[0]}%)
                    </S.CustomPText>
                }
            </S.CustomDiv>
        </S.CustomFlex>
    )
}