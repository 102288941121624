import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

export const Container = styled(ToastContainer)`
    .Toastify__toast {
    font-size: 16px;
    border-radius: 50px;
    color: #fff;
    background: rgba(107, 115, 135, 0.8);

    margin : 0 auto;
    margin-bottom: 10rem;
    margin-left:2rem;
    margin-right:2rem;
    padding-top:1.2rem;
    padding-bottom:1.2rem;
    padding-left:2rem;
    padding-right:2rem;
    min-height: auto;
  }

  .Toastify__toast-icon {
    width: 2rem;
    height: 2rem;
  }
  .Toastify__toast-body{
    text-align: left;
    font-size: 1.4rem;
  }
  .Toastify__toast--error {
    background: rgba(34, 34, 34, 0.7);
  }
`