import React from 'react'
import * as S from '../theme/style'
import { colors } from '../theme/color'
import { SurveyListCard } from '../component/List/SurveyListCard'
import { useNavigate } from 'react-router'
import { useGetMutation, useGetQuery } from '../hook/ReactQueryHook'
import { useUserInfoStore } from '../store'
import { SurveyListCardItemType } from './CommonScreenType'
import { AlertModal } from '../component/Modal/AlertModal'
import { IframeModal } from '../component/Modal/IframeModal'

export const SurveyMainList = () => {
  const { memberId, token, refreshToken , updateUserInfo } = useUserInfoStore((state) => state)
  const navigation = useNavigate()


  const [alertModal, setAlertModal] = React.useState({
    alert: false,
    errorCode: '',
    msg: '',
  })
  const [linkInfo, setLinkInfo] = React.useState({
    visible: false,
    link: '',
  })
  const [surveyList, setSurveyList] = React.useState<SurveyListCardItemType[]>(
    []
  ) //서베이 리스트 반환 mutation 등록
  const [page, setPage] = React.useState(1);

  const getSurveyListMutation = useGetMutation('getSurveyList', '/surveys');

  const preventRef = React.useRef(true)
  const obsRef = React.useRef(null)

  const obsHandler = (entries: IntersectionObserverEntry[]) => {
    //infinity scroll 바닥에 닿였을때 액션
    const target = entries[0]
    if (target.isIntersecting && preventRef.current) {
      preventRef.current = false
      setPage((prev) => prev + 1)
    }
  }

  const getSurveyListHandler = async () => {
    const params = {
      postData: {},
      headConfig: {
        memberId: memberId,
        Authorization: `Bearer ${token}`,
        refreshToken: `Bearer ${refreshToken}`,
      },
    }
    await getSurveyListMutation
      .mutateAsync(params)
      .then((res) => {
        const { code, data, msg, timestamp, refreshInfo } = res;

        if (refreshInfo) {
          updateUserInfo({
            accessTime: new Date(),
            memberId: refreshInfo.memberId,
            token: refreshInfo.tokenInfo.accessToken,
            refreshToken: refreshInfo.tokenInfo.refreshToken,
            isNewMember: refreshInfo.isNewMember,
          })
        }
        
        if (code === 'SUCCESS') {
          // console.log(data.surveys)
          setSurveyList([...data.surveys])
        } else {
        }
      })
      .catch((err) => {
        const { errCode, errMsg } = err.response.data

        setAlertModal({
          alert: true,
          errorCode: errCode,
          msg: errMsg,
        })
      })
  }

  React.useEffect(() => {
    //무한스크롤 observer 생성
    const observer = new IntersectionObserver(obsHandler, { threshold: 0.5 })
    if (obsRef.current) observer.observe(obsRef.current)
    return () => {
      observer.disconnect()
    }
  }, [])

  React.useEffect(() => {
    const updateArrayByPage = () => {
      const pageSize = 10 // 한 페이지에 보여줄 아이템 개수
      const startIndex = (page - 1) * pageSize // 현재 페이지의 시작 인덱스
      const endIndex = startIndex + pageSize // 현재 페이지의 끝 인덱스

      // 기존 배열인 TrashSurveyMainList에서 해당 페이지에 해당하는 일부를 선택
      const selectedItems = surveyList.slice(startIndex, endIndex)

      setSurveyList([...surveyList, ...selectedItems])
      preventRef.current = true
    }

    updateArrayByPage()
  }, [page])

  React.useEffect(() => {
    if (memberId !== '' && token !== '') {
      getSurveyListHandler()
    }
  }, [memberId])

  return (
    <S.BigContainter>
      <AlertModal
        isOpen={alertModal.alert}
        msg={alertModal.msg}
        errorCode={alertModal.errorCode}
        close={() => {
          setAlertModal({ alert: false, msg: '', errorCode: '' })
        }}
      />
      <S.ContainerXl>
        <S.CustomFlex
          br={15}
          bgcolor={colors.SUB_BACKGROUND_COLOR1}
          pl={2}
          pr={2}
          pt={1.5}
          pb={1.5}
          mt={1}
          flex_direction="row"
          justify_content="space-between"
          align_items="center"
          onClick={() => {
            navigation('/PointList')
          }}
        >
          <S.CustomFlex flex_direction="row" align_items="center">
            <S.CustomImg
              src={require('../assets/img/ico_point.png')}
              width={5.4}
              height={3.1}
            />
            <S.CustomPText fs={1.5} fw={'Bold'}>
              포인트 확인
            </S.CustomPText>
          </S.CustomFlex>
          <S.CustomImg
            src={require('../assets/img/ico_arrow.png')}
            width={2.7}
            height={2}
          />
        </S.CustomFlex>
        <S.MarginCom mt={2} />
        {surveyList.map((item, index) => {
          return (
            <SurveyListCard item={item} setLinkInfo={setLinkInfo} key={index} />
          )
        })}

        <div ref={obsRef} style={{ paddingTop: '1rem' }} />
      </S.ContainerXl>
      <IframeModal
        isOpen={linkInfo.visible}
        onClose={() => { setLinkInfo({ visible: false, link: '' }) }}
        iframeLink={ linkInfo.link}
      />
    </S.BigContainter>
  )
}
