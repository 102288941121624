import React from 'react'
import * as S from '../theme/style'
import { colors } from '../theme/color'

import { BarGraphEl } from '../component/progress/BarGraphEl'
import { color } from 'framer-motion'
import {
  VerticalGraphData,
  resultDotScoreData,
  trashImgList,
} from '../utils/trashData'
import { HorizontalGraphEl } from '../component/progress/HorizontalGraphEl'
import { SurveyDotSelect } from '../component/List/SurveyDotSelect'
import { SurveyStarSelect } from '../component/List/SurveyStarSelect'
import { SurvayImgSoloSel } from '../component/List/SurvayImgSoloSel'
import { useLocation } from 'react-router'
import { useGetMutation } from '../hook/ReactQueryHook'
import { useUserInfoStore } from '../store'
import { AlertModal } from '../component/Modal/AlertModal'

interface AnswerListItemType {
  title: string //답변값 제목
  value: number //답변값 통계
  isSelected: boolean //사용자 선택 유무
}
interface ResultListItemType {
  answerList: AnswerListItemType[] //답변 목록
  questionId: string //질문 id
  showType: string //질문 타입
  title: string //질문 제목
}

export const SurveyQuickResult = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const surveyId = queryParams.get('surveyId')

  const { token, memberId, refreshToken, updateUserInfo } = useUserInfoStore((state) => state);
  console.log(token);
  const getQuickResultMutation = useGetMutation(
    'getQuickResult',
    `/surveys/${surveyId}/quick_result`
  ) //설문조사 결과 불러오기

  const perList = [30, 20, 15, 25, 10]
  const imgPerList = [30, 20, 15, 10, 10, 15]

  const [selImgSolValue, setSelImgSolValue] = React.useState<number | ''>(1)

  const [resultList, setResultList] = React.useState<ResultListItemType[]>([])

  const [alertModal, setAlertModal] = React.useState({
    alert: false,
    errorCode: '',
    msg: '',
  })

  const getQuickResultHandler = async () => {
    const params = {
      postData: {},
      headConfig: {
        memberId: memberId,
        Authorization: `Bearer ${token}`,
        refreshToken: `Bearer ${refreshToken}`,
      },
    }

    await getQuickResultMutation
      .mutateAsync(params)
      .then((res) => {
          const { code, data, refreshInfo } = res;

          if (refreshInfo) {
            updateUserInfo({
              accessTime: new Date(),
              memberId: refreshInfo.memberId,
              token: refreshInfo.tokenInfo.accessToken,
              refreshToken: refreshInfo.tokenInfo.refreshToken,
              isNewMember: refreshInfo.isNewMember,
            })
          }

        if (code === 'SUCCESS') {
          console.log('isdata ? ', data.resultList)
          setResultList([...data.resultList])
        }
      })
      .catch((err) => {
        const { errCode, errMsg } = err.response.data

        setAlertModal({
          alert: true,
          errorCode: errCode,
          msg: errMsg,
        })
      })
  }

  React.useEffect(() => {
    getQuickResultHandler()
  }, [])

  return (
    <S.CustomDiv>
      <AlertModal
        isOpen={alertModal.alert}
        msg={alertModal.msg}
        errorCode={alertModal.errorCode}
        close={() => {
          setAlertModal({ alert: false, msg: '', errorCode: '' })
        }}
      />
      <S.CustomFlex
        bgcolor={colors.SUB_BACKGROUND_COLOR3}
        pt={2}
        pb={2}
        pl={4}
        pr={4}
        flex_direction="row"
        justify_content="space-between"
        align_items="center"
      >
        <S.CustomPText fs={1.8} fw={'Bold'} fc={colors.MAIN_COLOR}>
          설문 조사 결과
        </S.CustomPText>
        <S.CustomImg width={6} src={require('../assets/img/ico_deco.png')} />
      </S.CustomFlex>
      {resultList.length > 0 &&
        resultList.map((resultItem, index) => {
          return (
            <S.CustomDiv key={index}>
              <S.ContainerXl>
                <S.CustomDiv pt={4} pb={4}>
                  <S.CustomPText
                    fs={1.8}
                    fw={'Medium'}
                    fc={colors.FONT_COLOR1}
                    mb={2}
                  >
                    {resultItem.title}
                  </S.CustomPText>
                  {resultItem.answerList.length <= 3 ? (
                    //한가지선택(짧은길이, 선택지가 2개까지) 또는 선택지가 여러개일때
                    <S.CustomFlex
                      flex_direction="row"
                      justify_content="space-between"
                      align_items="center"
                    >
                      {resultItem.answerList.map((answerItem, aIndex) => {
                        return (
                          <BarGraphEl
                            key={aIndex}
                            index={aIndex}
                            item={answerItem}
                            itemArray={resultItem.answerList}
                          />
                        )
                      })}
                    </S.CustomFlex>
                  ) : resultItem.answerList.length >= 3 ? (
                    //한가지선택(긴길이), 선택지 3개이상일경우 , 랭킹일경우
                    <S.CustomFlex>
                      {resultItem.answerList.map((answerItem, aIndex) => {
                        return (
                          <HorizontalGraphEl
                            key={aIndex}
                            index={aIndex}
                            item={answerItem}
                          />
                        )
                      })}
                    </S.CustomFlex>
                  ) : resultItem.showType === 'INDEX_STAR' ? (
                    //점수선택

                    <S.CustomFlex
                      flex_direction={'row'}
                      // align_items='center'
                      justify_content="space-between"
                      style={{ gap: '1rem' }}
                    >
                      {resultItem.answerList.map((answerItem, aIndex) => {
                        return (
                          <SurveyStarSelect
                            key={aIndex}
                            selStarValue={
                              answerItem.isSelected ? String(aIndex + 1) : '0'
                            }
                            perList={perList}
                            starValue={String(aIndex + 1)}
                            title={answerItem.title}
                          />
                        )
                      })}
                    </S.CustomFlex>
                  ) : null}
                </S.CustomDiv>
              </S.ContainerXl>
              <S.CustomDiv
                pt={1}
                bgcolor={colors.SUB_COLOR}
                bbw={0.1}
                bbc={colors.BORDER_COLOR5}
              />
            </S.CustomDiv>
          )
        })}
      {/* <S.ContainerXl> 
                <S.CustomDiv
                    pt={4} pb={4}
                >
                    <S.CustomPText
                        fs={1.8}
                        fw={'Medium'}
                        fc={colors.FONT_COLOR1}
                        mb={2}
                    >
                        성동구 미세먼지 실태 조사의 심각성에 대해 얼마나 알고 있으시나요?
                    </S.CustomPText>
                    <S.CustomFlex
                        flex_direction='row'
                        justify_content='space-between'
                        align_items='center'
                    >
                        {VerticalGraphData().map((item,index) => {
                            return(
                                <BarGraphEl
                                    key={index} 
                                    item={item}
                                    scoreArray={VerticalGraphData()}
                                />
                            )
                        })}
                    </S.CustomFlex>
                </S.CustomDiv> 
            </S.ContainerXl>
            <S.CustomDiv
                pt={1}
                bgcolor={colors.SUB_COLOR}
                bbw={0.1}
                bbc={colors.BORDER_COLOR5}
            />

            <S.ContainerXl> 
                <S.CustomDiv
                    pt={4} pb={4}
                >
                    <S.CustomPText
                        fs={1.8}
                        fw={'Medium'}
                        fc={colors.FONT_COLOR1}
                        mb={2}
                    >
                        인터넷을 얼마나 자주 사용합니까?(선택값이 긴 형식)
                    </S.CustomPText>
                    <S.CustomFlex>
                        {VerticalGraphData().map((item,index) => {
                            return(
                                <HorizontalGraphEl
                                    key={index} 
                                    item={item}
                                    scoreArray={VerticalGraphData()}
                                />
                            )
                        })}
                    </S.CustomFlex>
                </S.CustomDiv> 
            </S.ContainerXl>
            <S.CustomDiv
                pt={1}
                bgcolor={colors.SUB_COLOR}
                bbw={0.1}
                bbc={colors.BORDER_COLOR5}
            />
            <S.ContainerXl> 
                <S.CustomDiv
                    pt={4} pb={2}
                >
                    <S.CustomPText
                        fs={1.8}
                        fw={'Medium'}
                        fc={colors.FONT_COLOR1}
                        mb={2}
                    >
                        성동구 미세먼지 실태 조사의 심각성에 대해 얼마나 알고 있으시나요?
                    </S.CustomPText>
                    <S.CustomFlex
                        flex_direction='row'
                        justify_content='space-between'
                        align_items='center'
                    >
                        {VerticalGraphData().map((item,index) => {
                            return(
                                <BarGraphEl
                                    key={index} 
                                    item={item}
                                    scoreArray={VerticalGraphData()}
                                />
                            )
                        })}
                    </S.CustomFlex>
                        <S.CustomDiv
                            pa={3}
                            bgcolor={colors.SUB_BACKGROUND_COLOR6}
                            br={2}
                            mt={2}
                        >
                        <S.CustomPText
                            fs={1.5}
                            fc={colors.MAIN_COLOR}
                        >
                            직접 입력한 글귀가 나타납니다.
                            직접 입력한 글귀가 나타납니다.
                            직접 입력한 글귀가 나타납니다.
                        </S.CustomPText>
                    </S.CustomDiv> 
                </S.CustomDiv>
            </S.ContainerXl>
            <S.CustomDiv
                pt={1}
                bgcolor={colors.SUB_COLOR}
                bbw={0.1}
                bbc={colors.BORDER_COLOR5}
            />

            <S.ContainerXl> 
                <S.CustomDiv
                    pt={4} pb={4}
                >
                    <S.CustomPText
                        fs={1.8}
                        fw={'Medium'}
                        fc={colors.FONT_COLOR1}
                        mb={3}
                    >
                        성동구 미세먼지 실태 조사의 심각성에 대해 얼마나 알고 있으시나요?
                    </S.CustomPText>
                    <SurveyDotSelect 
                        selDotValue={4}
                    />
                    <S.MarginCom mb={1} />
                    <S.DotDataList>
                        {resultDotScoreData.map((item,index) => {
                            return(
                                <S.CustomFlex
                                    key={index}
                                    flex_direction='row'
                                >
                                    <S.CustomDiv
                                        width={6}
                                    >
                                        <S.CustomPText
                                            fs={1.5}
                                            fc={colors.FONT_COLOR1}
                                            fw={'Medium'}
                                        >
                                            {item.score}점
                                        </S.CustomPText>
                                    </S.CustomDiv>
                                    <S.CustomPText
                                        fs={1.5}
                                        fc={colors.FONT_COLOR1}
                                        fw={'Medium'}
                                    >
                                        {item.percent.toFixed(1)}%
                                    </S.CustomPText>
                                </S.CustomFlex>
                            )
                        })}
                        
                    </S.DotDataList>
                </S.CustomDiv>
            </S.ContainerXl>
            <S.CustomDiv
                pt={1}
                bgcolor={colors.SUB_COLOR}
                bbw={0.1}
                bbc={colors.BORDER_COLOR5}
            />
            <S.ContainerXl> 
                <S.CustomDiv
                    pt={4} pb={6}
                >
                    <S.CustomPText
                        fs={1.8}
                        fw={'Medium'}
                        fc={colors.FONT_COLOR1}
                        mb={3}
                    >
                        성동구 미세먼지 실태 조사의 심각성에 대해 얼마나 알고 있으시나요?
                    </S.CustomPText>
                    <SurveyStarSelect 
                        selStarValue={'3'}
                        perList={perList}
                    />
                    
                </S.CustomDiv>
            </S.ContainerXl>
            <S.CustomDiv
                pt={1}
                bgcolor={colors.SUB_COLOR}
                bbw={0.1}
                bbc={colors.BORDER_COLOR5}
            />

            <S.ContainerXl> 
                <S.CustomDiv
                    pt={4} pb={6}
                >
                    <S.CustomPText
                        fs={1.8}
                        fw={'Medium'}
                        fc={colors.FONT_COLOR1}
                        mb={3}
                    >
                        성동구 미세먼지 실태 조사의 심각성에 대해 얼마나 알고 있으시나요?
                    </S.CustomPText>
                    <SurvayImgSoloSel 
                        imgArray={trashImgList}
                        selValue={selImgSolValue}
                        isResult={true}
                        perArray={imgPerList}
                    />
                    
                </S.CustomDiv>
            </S.ContainerXl> */}
    </S.CustomDiv>
  )
}
