import { colors } from "../theme/color";

export const TrashSurveyMainList = [
    {
        acc_price : 200,
        acc_type : 'normal_survey',
        acc_count : 9,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1000,
        acc_type : 'quick_survey',
        acc_count : 11,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1500,
        acc_type : 'detail_survey',
        acc_count : 50,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.WHITE_RED_COLOR,
    },
    {
        acc_price : 200,
        acc_type : 'normal_survey',
        acc_count : 9,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1000,
        acc_type : 'quick_survey',
        acc_count : 11,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1500,
        acc_type : 'detail_survey',
        acc_count : 50,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.WHITE_RED_COLOR,
    },
    {
        acc_price : 200,
        acc_type : 'normal_survey',
        acc_count : 9,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1000,
        acc_type : 'quick_survey',
        acc_count : 11,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1500,
        acc_type : 'detail_survey',
        acc_count : 50,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.WHITE_RED_COLOR,
    },
    {
        acc_price : 200,
        acc_type : 'normal_survey',
        acc_count : 9,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1000,
        acc_type : 'quick_survey',
        acc_count : 11,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1500,
        acc_type : 'detail_survey',
        acc_count : 50,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.WHITE_RED_COLOR,
    },
    {
        acc_price : 200,
        acc_type : 'normal_survey',
        acc_count : 9,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1000,
        acc_type : 'quick_survey',
        acc_count : 11,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1500,
        acc_type : 'detail_survey',
        acc_count : 50,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.WHITE_RED_COLOR,
    },
    {
        acc_price : 200,
        acc_type : 'normal_survey',
        acc_count : 9,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1000,
        acc_type : 'quick_survey',
        acc_count : 11,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1500,
        acc_type : 'detail_survey',
        acc_count : 50,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.WHITE_RED_COLOR,
    },
    {
        acc_price : 200,
        acc_type : 'normal_survey',
        acc_count : 9,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1000,
        acc_type : 'quick_survey',
        acc_count : 11,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1500,
        acc_type : 'detail_survey',
        acc_count : 50,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.WHITE_RED_COLOR,
    },
    {
        acc_price : 200,
        acc_type : 'normal_survey',
        acc_count : 9,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1000,
        acc_type : 'quick_survey',
        acc_count : 11,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1500,
        acc_type : 'detail_survey',
        acc_count : 50,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.WHITE_RED_COLOR,
    },
    {
        acc_price : 200,
        acc_type : 'normal_survey',
        acc_count : 9,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1000,
        acc_type : 'quick_survey',
        acc_count : 11,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1500,
        acc_type : 'detail_survey',
        acc_count : 50,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.WHITE_RED_COLOR,
    },
    {
        acc_price : 200,
        acc_type : 'normal_survey',
        acc_count : 9,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1000,
        acc_type : 'quick_survey',
        acc_count : 11,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1500,
        acc_type : 'detail_survey',
        acc_count : 50,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.WHITE_RED_COLOR,
    },
    {
        acc_price : 200,
        acc_type : 'normal_survey',
        acc_count : 9,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1000,
        acc_type : 'quick_survey',
        acc_count : 11,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1500,
        acc_type : 'detail_survey',
        acc_count : 50,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.WHITE_RED_COLOR,
    },
    {
        acc_price : 200,
        acc_type : 'normal_survey',
        acc_count : 9,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1000,
        acc_type : 'quick_survey',
        acc_count : 11,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1500,
        acc_type : 'detail_survey',
        acc_count : 50,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.WHITE_RED_COLOR,
    },
    {
        acc_price : 200,
        acc_type : 'normal_survey',
        acc_count : 9,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1000,
        acc_type : 'quick_survey',
        acc_count : 11,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1500,
        acc_type : 'detail_survey',
        acc_count : 50,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.WHITE_RED_COLOR,
    },
    {
        acc_price : 200,
        acc_type : 'normal_survey',
        acc_count : 9,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1000,
        acc_type : 'quick_survey',
        acc_count : 11,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1500,
        acc_type : 'detail_survey',
        acc_count : 50,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.WHITE_RED_COLOR,
    },
    {
        acc_price : 200,
        acc_type : 'normal_survey',
        acc_count : 9,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1000,
        acc_type : 'quick_survey',
        acc_count : 11,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1500,
        acc_type : 'detail_survey',
        acc_count : 50,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.WHITE_RED_COLOR,
    },
    {
        acc_price : 200,
        acc_type : 'normal_survey',
        acc_count : 9,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1000,
        acc_type : 'quick_survey',
        acc_count : 11,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1500,
        acc_type : 'detail_survey',
        acc_count : 50,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.WHITE_RED_COLOR,
    },
    {
        acc_price : 200,
        acc_type : 'normal_survey',
        acc_count : 9,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1000,
        acc_type : 'quick_survey',
        acc_count : 11,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1500,
        acc_type : 'detail_survey',
        acc_count : 50,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.WHITE_RED_COLOR,
    },
    {
        acc_price : 200,
        acc_type : 'normal_survey',
        acc_count : 9,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1000,
        acc_type : 'quick_survey',
        acc_count : 11,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1500,
        acc_type : 'detail_survey',
        acc_count : 50,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.WHITE_RED_COLOR,
    },
    {
        acc_price : 200,
        acc_type : 'normal_survey',
        acc_count : 9,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1000,
        acc_type : 'quick_survey',
        acc_count : 11,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1500,
        acc_type : 'detail_survey',
        acc_count : 50,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.WHITE_RED_COLOR,
    },
    {
        acc_price : 200,
        acc_type : 'normal_survey',
        acc_count : 9,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1000,
        acc_type : 'quick_survey',
        acc_count : 11,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1500,
        acc_type : 'detail_survey',
        acc_count : 50,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.WHITE_RED_COLOR,
    },
    {
        acc_price : 200,
        acc_type : 'normal_survey',
        acc_count : 9,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1000,
        acc_type : 'quick_survey',
        acc_count : 11,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1500,
        acc_type : 'detail_survey',
        acc_count : 50,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.WHITE_RED_COLOR,
    },
    {
        acc_price : 200,
        acc_type : 'normal_survey',
        acc_count : 9,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1000,
        acc_type : 'quick_survey',
        acc_count : 11,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1500,
        acc_type : 'detail_survey',
        acc_count : 50,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.WHITE_RED_COLOR,
    },
    {
        acc_price : 200,
        acc_type : 'normal_survey',
        acc_count : 9,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1000,
        acc_type : 'quick_survey',
        acc_count : 11,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1500,
        acc_type : 'detail_survey',
        acc_count : 50,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.WHITE_RED_COLOR,
    },
    {
        acc_price : 200,
        acc_type : 'normal_survey',
        acc_count : 9,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1000,
        acc_type : 'quick_survey',
        acc_count : 11,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1500,
        acc_type : 'detail_survey',
        acc_count : 50,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.WHITE_RED_COLOR,
    },
    {
        acc_price : 200,
        acc_type : 'normal_survey',
        acc_count : 9,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1000,
        acc_type : 'quick_survey',
        acc_count : 11,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1500,
        acc_type : 'detail_survey',
        acc_count : 50,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.WHITE_RED_COLOR,
    },
    {
        acc_price : 200,
        acc_type : 'normal_survey',
        acc_count : 9,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1000,
        acc_type : 'quick_survey',
        acc_count : 11,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1500,
        acc_type : 'detail_survey',
        acc_count : 50,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.WHITE_RED_COLOR,
    },
    {
        acc_price : 200,
        acc_type : 'normal_survey',
        acc_count : 9,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1000,
        acc_type : 'quick_survey',
        acc_count : 11,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1500,
        acc_type : 'detail_survey',
        acc_count : 50,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.WHITE_RED_COLOR,
    },
    {
        acc_price : 200,
        acc_type : 'normal_survey',
        acc_count : 9,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1000,
        acc_type : 'quick_survey',
        acc_count : 11,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1500,
        acc_type : 'detail_survey',
        acc_count : 50,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.WHITE_RED_COLOR,
    },
    {
        acc_price : 200,
        acc_type : 'normal_survey',
        acc_count : 9,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1000,
        acc_type : 'quick_survey',
        acc_count : 11,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1500,
        acc_type : 'detail_survey',
        acc_count : 50,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.WHITE_RED_COLOR,
    },
    {
        acc_price : 200,
        acc_type : 'normal_survey',
        acc_count : 9,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1000,
        acc_type : 'quick_survey',
        acc_count : 11,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.SKY_BLUE_COLOR,
    },
    {
        acc_price : 1500,
        acc_type : 'detail_survey',
        acc_count : 50,
        title1 : '성공적 재테크',
        title2 : '를 위한 첫걸음',
        start_date : '2023년 06월 13일',
        end_date : '2023년 06월 15일',
        contents : '습관의 힘은 아무리 강조해도 지나치지 않죠. 사소한 습관 하나가 미래를 달라지게 합니다. 그러니 많은 부모들이아러아러아',
        hashTag : ['재테크','투자','습관의힘'],
        color: colors.WHITE_RED_COLOR,
    },
];

export const trashImgList = [
    {
        idx : 1,
        imgLink : require('../assets/img/thum_1.jpg'),
        detailImgLink : require('../assets/img/detail_img_1.jpg')
    },
    {
        idx : 2,
        imgLink : require('../assets/img/thum_2.jpg'),
        detailImgLink : require('../assets/img/detail_img_2.jpg')
    },
    {
        idx : 3,
        imgLink : require('../assets/img/thum_3.jpg'),
        detailImgLink : require('../assets/img/detail_img_3.jpg')
    },
    {
        idx : 4,
        imgLink : require('../assets/img/thum_4.jpg'),
        detailImgLink : require('../assets/img/detail_img_4.jpg')
    },
    {
        idx : 5,
        imgLink : require('../assets/img/thum_5.jpg'),
        detailImgLink : require('../assets/img/detail_img_5.jpg')
    },
    {
        idx : 6,
        imgLink : require('../assets/img/thum_6.jpg'),
        detailImgLink : require('../assets/img/detail_img_6.jpg')
    },
]


export const pointList = () => {

    let pointArray = [];

    for(let i=0; i<=50; i++){
        pointArray.push(
            {
                index : i,
                title : i+'번째 성동구 미세먼지 실태조사 설문완료',
                point : 200+i,
                type : '적립',
                date : '2023.03.03',
            },
        )
    }
    return pointArray;
}

export const VerticalGraphData = () => {
    let dataList = [];
    let labelList = ['예','아니오','보통'];
    const tempScoreArray = [80,0,20];
    for(let i=0; i<3; i++){
        dataList.push({
            index : i,
            title : labelList[i],
            percent : tempScoreArray[i],
        })
    }

    return dataList;
}

export const resultDotScoreData = [
    {
        score : 1,
        percent : 17
    },
    {
        score : 2,
        percent : 5
    },
    {
        score : 3,
        percent : 34
    },
    {
        score : 4,
        percent : 2
    },
    {
        score : 5,
        percent : 1
    },
    {
        score : 6,
        percent : 9
    },
    {
        score : 7,
        percent : 7
    },
    {
        score : 8,
        percent : 15
    },
    {
        score : 9,
        percent : 8
    },
    {
        score : 10,
        percent : 2
    },
]