import { useQuery, useMutation } from 'react-query'
import axios from 'axios'
import { axiosInstance } from '../api/instance'
import QueryString from 'qs'

export const refreshTokenHandler = async (headerConfig: any) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      memberId: headerConfig.memberId,
      Authorization: headerConfig.refreshToken,
    },
  }

  const data = await axiosInstance.post('/auth/refresh', {}, config)

  return data
}

/** 토큰 유효성 검사 */
export const checkTokenHandler = async (headerConfig: object) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
        ...headerConfig,
    },
  }

  const data = await axiosInstance
    .post('/auth/validate', {}, config)
    .then((res) => {
      return res
    })
    .catch(async (err) => {
      const refresh = await refreshTokenHandler(headerConfig).then(
        async (res) => {
          const { data: refreshData } = res
          return refreshData
        }
      )

      return refresh
    })

  return data
}

export const fetchPostData = async (
  postData: object,
  apiUrl: string,
  headerConfig: any
) => {
    
  const config = {
    headers: {
      'Content-Type': 'application/json',
      ...headerConfig,
    },
  }
  if (headerConfig.memberId) {
    const postResultData = await checkTokenHandler(headerConfig).then(
      async (res) => {
        const { data } = res
        const { code, data: resultData, msg,tokenInfo, } = data


        if (resultData) {
          const { data: actionData } = await axiosInstance.post(
            apiUrl,
            postData,
            config
          )
          return actionData
        } else {
            const refreshConfig = {
              headers: {
                'Content-Type': 'application/json',
                ...headerConfig,
                memberId: data.memberId,
                Authorization: `Bearer ${tokenInfo.accessToken}`,
              },
            }

            const { data: actionData } = await axiosInstance.post(
              apiUrl,
              postData,
              refreshConfig
            )

            return {
                code: actionData.code,
                msg: actionData.msg,
                data : actionData.data,
                refreshInfo : data,
            }
        }
      }
    )
    return postResultData
  } else {
    const { data } = await axiosInstance.post(apiUrl, postData, config)

    return data
  }
}
export const fetchGetData = async (
  postData: object,
  apiUrl: string,
  headerConfig: any,
) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            ...headerConfig,
        },
        params: postData,
    }
    
    if (headerConfig.memberId) {
      const postResultData = await checkTokenHandler(headerConfig).then(
        async (res) => {
          const { data } = res //토큰 유효 결과
          const { code, data: resultData, msg, tokenInfo } = data //리프레쉬 결과

          if (resultData) {
            const { data: actionData } = await axiosInstance.get(apiUrl, config)
            return actionData
          } else {
              
            const refreshConfig = {
                headers: {
                    'Content-Type': 'application/json',
                    ...headerConfig,
                    memberId: data.memberId,
                    Authorization: `Bearer ${tokenInfo.accessToken}`,
                },
                params : postData,
              }
              console.log(refreshConfig);

            const { data: actionData } = await axiosInstance.get(apiUrl, refreshConfig);

            return {
              code: actionData.code,
              msg: actionData.msg,
              data: actionData.data,
              refreshInfo: data,
            }
          }
        }
      )
      return postResultData
    } else {
      const { data } = await axiosInstance.get(apiUrl, config)

      return data
    }

  
}

export const fetchPutData = async (apiUrl: string, headerConfig: any) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      ...headerConfig,
    },
    }
    
    if (headerConfig.memberId) {
        const postResultData = await checkTokenHandler(headerConfig).then(
            async (res) => {
                const { data } = res //토큰 유효 결과
                const { code, data: resultData, msg, tokenInfo } = data //리프레쉬 결과

                if (resultData) {
                    const { data: actionData } = await axiosInstance.put(apiUrl, null, config)
                    return actionData
                } else {
              
                    const refreshConfig = {
                        headers: {
                            'Content-Type': 'application/json',
                            ...headerConfig,
                            memberId: data.memberId,
                            Authorization: `Bearer ${tokenInfo.accessToken}`,
                        },
                    }
                    console.log(refreshConfig);

                    const { data: actionData } = await axiosInstance.put(apiUrl, null, refreshConfig)

                    return {
                        code: actionData.code,
                        msg: actionData.msg,
                        data: actionData.data,
                        refreshInfo: data,
                    }
                }
            }
        )
        return postResultData
    } else {

        const { data } = await axiosInstance.put(apiUrl, null, config)

        return data
    }
}

export const fetchDeleteData = async (apiUrl: string, headerConfig: object) => {
  const config = {
    headers: {
      ...headerConfig,
    },
  }

  const { data } = await axiosInstance.delete(apiUrl, config)

  return data
}

export const usePostQuery = ( postKey: string, postData: object, apiUrl: string,headConfig: object) => {
  //post query 데이터 반환
  return useQuery([postKey, postData], () => fetchPostData(postData, apiUrl, headConfig))
}

export const usePostMutation = (postKey: string, apiUrl: string) => {
  //post mutation 데이터 반환
    return useMutation(({ postData, headConfig }: { postData: object; headConfig: object } ) => fetchPostData(postData, apiUrl, headConfig), { mutationKey: postKey } )
}

export const useGetQuery = ( getKey: string, postData: object, apiUrl: string, headConfig: object ) => {
  //get query 요청
  return useQuery(getKey, () => fetchGetData(postData, apiUrl, headConfig))
}

export const useGetMutation = (getKey: string, apiUrl: string) => {
  //get mutation 요청
  return useMutation( ({ postData, headConfig }: { postData: object; headConfig: object }) => fetchGetData(postData, apiUrl, headConfig), { mutationKey: getKey })
}

export const usePutMutation = (putKey: string) => {
  //put 요청
  return useMutation( ({ apiUrl, headConfig }: { apiUrl: string; headConfig: object }) => fetchPutData(apiUrl, headConfig), { mutationKey: putKey } )
}

export const useDeleteMutation = (deleteKey: string) => {
  //delete 요청
  return useMutation( ({ apiUrl, headConfig }: { apiUrl: string; headConfig: object }) => fetchDeleteData(apiUrl, headConfig), { mutationKey: deleteKey } )
}
