import React from 'react';
import * as S from '../../theme/style';
import Modal from 'react-modal';
import { ImageDetailViewModalType } from '../ComponentCommonType';
import { colors } from '../../theme/color';



export const ImageDetailViewModal = ({
    isOpen,
    onClose,
    imgLink,
}:ImageDetailViewModalType) =>{
    return(
        <Modal 
            isOpen={isOpen}
            style={{content:{
                width:'100%',
                height:'100%',
                inset:0,
                padding:0,
                borderRadius:0,
                border:'none',
            }}}
        >
            <S.CustomFlex 
                width={'100%'} 
                height={'100%'}
            >
                <S.CustomFlex
                    flex={8}
                    justify_content='center'
                    align_items='center'
                >
                     <S.CustomImg
                        width={'100%'}
                        src={imgLink}
                        object_fit='contain'
                    />
                </S.CustomFlex>
                <S.CustomFlex
                    flex={2}
                    width={'100%'}
                    justify_content='center'
                    align_items='center'
                >
                    <S.CustomFlex
                        br={15}
                        bgcolor={colors.BORDER_COLOR3}
                        pt={2} pb={2} pl={3} pr={3}
                        flex_direction='row'
                        justify_content='space-around'
                        align_items='center'
                        onClick={onClose}
                    >
                        <S.CustomPText
                            fs={1.4}
                            fc={colors.WHITE}                            
                        >
                            확대 이미지 닫기
                        </S.CustomPText>
                        <S.CustomImg 
                            width={2.5}
                            height={2.5}
                            ml={1}
                            src={require('../../assets/img/ico_close_w.png')}
                        />
                    </S.CustomFlex>
                </S.CustomFlex>
            </S.CustomFlex>
        </Modal>
    )
}