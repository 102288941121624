import React from 'react';
import { useUserInfoStore } from '../../store';
import { useNavigate } from 'react-router';

export const MemberAuth = () => {
    const {memberId, token,isNewMember} = useUserInfoStore(state => state);
    
    const navigation = useNavigate();

    React.useEffect(()=>{
        if(token !== ''){
            if(isNewMember){
                navigation('/Intro',{replace:true});
            }
            else{
                navigation('/SurveyList',{replace:true});
            }
        }
    },[token])

    return(
        <>
        </>
    )
}