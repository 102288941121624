import React from 'react';
import * as S from '../../theme/style';
import * as CS from '../ComponentStyle';
import { SurveyDotSelectType } from '../ComponentCommonType';
import { colors } from '../../theme/color';

export const SurveyDotSelect = ({ //점수선택 1~10
    selDotValue,
    selectDotHandler,
    choiceList,
    isLabel,
    questionId,
}:SurveyDotSelectType) => {
    
    const scoreList = [1,2,3,4,5,6,7,8,9,10];

    React.useEffect(()=>{
        console.log(choiceList);
    },[])
    
    return(
        <S.CustomDiv>
            <S.CustomFlex
                flex_direction='row'
                justify_content='space-between'
                // align_items='center'
            >
                {choiceList.map((item,index) => {
                    return(
                        <CS.DotSelectorWrapper
                            key={index}
                            // style={{overflowY:'hidden'}}
                            content={
                                isLabel ? 
                                    choiceList.length % 2 === 0 ?
                                        (index === 0 || choiceList.length-1 === index) ? item.title : undefined
                                    :
                                        (index === 0 || choiceList.length-1 === index || choiceList.length/2+1 === index+1) ? item.title : undefined

                                : 
                                    undefined
                            }
                            ab_text_align={index === 0 || index === 4 ? 'left' : 'right'}
                            asolute_right={choiceList.length-1 === index ? 0 : undefined}
                            absolute_left={index === 4 ? 0.3 : undefined}
                        >
                            <S.CustomFlex
                                key={index}
                                justify_content='center'
                                align_items='center'
                                br={999}
                                onClick={()=>{
                                    // if(selectDotHandler)selectDotHandler(item);
                                    if(selectDotHandler && questionId){
                                        selectDotHandler(item.value,questionId,item.choiceId,false,false);
                                    }
                                }}
                                
                            >
                                <S.CustomFlex
                                    br={999}
                                    bc={selDotValue === item.value ? colors.MAIN_COLOR  : colors.BORDER_COLOR2}
                                    bw={0.1}
                                    width={2.5}
                                    height={2.5}
                                    align_items='center'
                                    justify_content='center'
                                    bgcolor={selDotValue === item.value  ? colors.MAIN_COLOR  : colors.WHITE}
                                    
                                >
                                    <S.CustomPText
                                        fs={1.3}
                                        fc={selDotValue === item.value ? colors.WHITE : colors.FONT_COLOR3}
                                    >
                                        {index+1}
                                    </S.CustomPText>
                                </S.CustomFlex>
                            </S.CustomFlex>
                        </CS.DotSelectorWrapper>
                        
                    )
                })}
                <S.CustomDiv                                
                    bw={0}
                    btw={0.1}
                    btc={colors.BORDER_COLOR2}
                    width={'100%'}
                    position={'absolute'}
                    style={{zIndex:-1,top:'25%',transform:'translate(0,50%)'}}
                />
            </S.CustomFlex>
        </S.CustomDiv>
    )
}

