import {create} from 'zustand';



interface UserInfoStateType {
    memberId : string,
    token : string,
    refreshToken : string,
    accessTime : Date | '',
    isNewMember : boolean,
    updateUserInfo : (userInfo:{
        memberId : string,
        token : string,
        refreshToken : string,
        accessTime : Date,
        isNewMember : boolean,
    }) => void;
}

const useUserInfoStore = create<UserInfoStateType>(set => ({
    memberId : '',
    token : '',
    refreshToken : '',
    accessTime : '',
    isNewMember : true,
    updateUserInfo : (userInfo) => {set({
        memberId : userInfo.memberId,
        token : userInfo.token,
        refreshToken : userInfo.refreshToken,
        accessTime : userInfo.accessTime,
        isNewMember : userInfo.isNewMember
    })}
}))

export {useUserInfoStore}