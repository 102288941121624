import React from 'react';

import * as S from '../../theme/style';
import { colors } from '../../theme/color';
import { useNavigate } from 'react-router';
import { SurveyListCardItemType } from '../../screen/CommonScreenType';
import { timeWithDateConverter } from '../../utils/func';
import { useGetMutation } from '../../hook/ReactQueryHook';
import { useUserInfoStore } from '../../store';


interface SurveyListCardType {
    item: SurveyListCardItemType
    setLinkInfo: (linkInfo : { visible: boolean, link: string }) => void;
}

export const SurveyListCard = ({ item, setLinkInfo }: SurveyListCardType) => {
  //설문 리스트 카드

  const navigation = useNavigate()
  const { memberId, token, refreshToken, updateUserInfo } = useUserInfoStore(
    (state) => state
  )

  const getPageListMutation = useGetMutation(
    'getPageList',
    `/surveys/${item.surveyId}/pages`
  )

  const moveSurveyDetail = async () => {
    // if(item.type === 'SURVEY' || item.type === 'POLL'){ //설문조사, 심층질문
    //     navigation('/SurveyDetail?',{state:{surveyItem : item}});
    // }
    // else{ //단순배너

    // }
    // if (item.status === 'OPEN') {
      const params = {
        postData: {},
        headConfig: {
          memberId: memberId,
          Authorization: `Bearer ${token}`,
          refreshToken: `Bearer ${refreshToken}`,
        },
      }

      await getPageListMutation.mutateAsync(params).then((res) => {
        const { code, data, msg, refreshInfo } = res

        if (refreshInfo) {
          updateUserInfo({
            accessTime: new Date(),
            memberId: refreshInfo.memberId,
            token: refreshInfo.tokenInfo.accessToken,
            refreshToken: refreshInfo.tokenInfo.refreshToken,
            isNewMember: refreshInfo.isNewMember,
          })
        }

        if (code === 'SUCCESS') {
          if (data.pages[0].type === 'INTRO') {
            console.log(data)
            navigation('/SurveyDetail', {
              state: { surveyItem: item, pageInfo: data.pages },
            })
          } else if (data.pages[0].type === 'LINK') {
            console.log(data)
            setLinkInfo({
              link: data.pages[0].iframeUrl,
              visible: true,
            })
          }
        }
      })

      // navigation('/SurveyDetail?',{state:{surveyItem : item}});
    // }
  }

  return (
    <S.CustomDiv
      mt={2}
      bbw={0.1}
      bbc={colors.BORDER_COLOR1}
      onClick={moveSurveyDetail}
    >
      {/** 이미지 */}
      <S.CustomDiv position={'relative'}>
        <S.CustomImg
          br={1}
          width={'100%'}
          src={
            item.introImg !== ''
              ? `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_HOST_VER}${item.introImg}`
              : require('../../assets/img/banner1.png')
          }
        />
        {item.basicRewardPoint && item.basicRewardPoint !== 0 ? (
          <S.CustomFlex
            pt={1}
            pb={1}
            pl={1.5}
            pr={1.5}
            bgcolor={colors.MAIN_COLOR}
            position={'absolute'}
            top={0}
            left={0}
            btlr={6}
            btrr={15}
            bbrr={15}
            flex_direction="row"
            justify_content="center"
            align_items="center"
          >
            <S.CustomImg
              width={2.5}
              height={2.5}
              src={require('../../assets/img/ico_point2.png')}
              mr={1}
            />

            <S.CustomSpanText fs={1.3} fc={colors.WHITE} fw={'Medium'}>
              {item.basicRewardPoint}P 적립
            </S.CustomSpanText>
          </S.CustomFlex>
        ) : null}
        {item.status !== 'OPEN' && (
          <S.CustomDiv
            position="absolute"
            left={0}
            bottom={0}
            width={'100%'}
            bgcolor="rgba(0,0,0,0.2)"
            pt={1}
            pb={1}
            bblr={1}
            bbrr={1}
          >
            <S.CustomSpanText fs={1.4} fc={colors.WHITE}>
              {(item.userJoinStatus === 'FINISH' || item.userJoinStatus === 'REWARDED') ? '참여완료' : item.status === 'CLOSE' ? '설문 종료' : item.status === 'QUATA_FULL' ? '참여 마감' : ''}
            </S.CustomSpanText>
          </S.CustomDiv>
        )}
      </S.CustomDiv>
      {/** 설문 타입 */}
      <S.CustomPText fs={1.3} mt={2} fc={colors.WHITE_GRAY_COLOR}>
        {item.type === 'SURVEY'
          ? `일반설문(총 ${item.totalQuestionCount}문항)`
          : item.type === 'POLL'
          ? `Quick Survey(총 ${item.totalQuestionCount}문항)`
          : `Quick Survey(총 ${item.totalQuestionCount}문항)`}
      </S.CustomPText>

      {/** 설문 제목 */}
      <S.CustomPText mt={1}>
        <S.CustomSpanText fs={2} fc={colors.WHITE_RED_COLOR} fw={'Bold'}>
          {item.introTitle}
        </S.CustomSpanText>
        {/* <S.CustomSpanText
                    fs={2}
                    fc={colors.FONT_COLOR1}
                    fw={'Bold'}
                >
                    {item.finishSubTitle}
                </S.CustomSpanText> */}
      </S.CustomPText>

      {/** 설문 기간 */}
      <S.CustomPText fs={1.3} mt={1} fc={colors.WHITE_GRAY_COLOR} fw={'Light'}>
        {timeWithDateConverter(item.planOpenDtti).stringDate} ~{' '}
        {timeWithDateConverter(item.planCloseDtti).stringDate}
      </S.CustomPText>

      {/** 설문 내용 */}
      {item.desc !== '' && item.desc && (
        <S.CustomPText fs={1.4} mt={2} fc={colors.FONT_COLOR2} fw={'Light'}>
          {item.desc}
        </S.CustomPText>
      )}

      {/** 설문 해시태그 */}
      {item.tags.length > 0 && (
        <S.CustomPText mt={2}>
          {item.tags.map((item, index) => {
            return (
              <S.CustomSpanText
                key={index}
                fc={colors.SKY_BLUE_COLOR}
                fs={1.4}
                mr={2}
              >
                #{item}
              </S.CustomSpanText>
            )
          })}
        </S.CustomPText>
      )}
      <S.CustomDiv mb={2} />
    </S.CustomDiv>
  )
}