
export interface colorType  {
    WHITE : string,
    BLACK : string,

    MAIN_COLOR : string,
    SKY_BLUE_COLOR : string,
    WHITE_RED_COLOR : string,
    WHITE_GRAY_COLOR : string,
    RED_COLOR : string,
    DEEP_WHITE_PURPLE_COLOR : string,
    DEEP_GRAY : string,

    SUB_COLOR : string,
    SUB_COLOR1 : string,

    FONT_COLOR1 : string,
    FONT_COLOR2 : string,
    FONT_COLOR3 : string,
    FONT_COLOR4 : string,

    BORDER_COLOR1 : string,
    BORDER_COLOR2 : string,
    BORDER_COLOR3 : string,
    BORDER_COLOR4 : string,
    BORDER_COLOR5 : string,
    BORDER_COLOR_BLACK : string,

    MAIN_BACKGROUND_COLOR1 : string,
    MAIN_BACKGROUND_COLOR2 : string,
    MAIN_BACKGROUND_COLOR3 : string,
    
    SUB_BACKGROUND_COLOR1 : string,
    SUB_BACKGROUND_COLOR2 : string,
    SUB_BACKGROUND_COLOR3 : string,
    SUB_BACKGROUND_COLOR4 : string,
    SUB_BACKGROUND_COLOR5 : string,
    SUB_BACKGROUND_COLOR6 : string,
    SUB_BACKGROUND_COLOR_PINK : string,
    SUB_BACKGROUND_COLOR_GRAY_BLUE : string,
    SUB_BACKGROUND_COLOR_WHITE_GRAY : string,

    ERROR_COLOR1 : string,
    ERROR_COLOR2 : string,
    ERROR_COLOR3 : string,

    SUCCESS_COLOR1 : string,
    SUCCESS_COLOR2 : string,
    SUCCESS_COLOR3 : string,
}

export const colors : colorType = {

    WHITE : '#FFF',
    BLACK : '#000',

    MAIN_COLOR : '#4737DF',
    SKY_BLUE_COLOR : '#3498ED',
    WHITE_RED_COLOR : '#FF3B6D',
    WHITE_GRAY_COLOR : '#999999',
    RED_COLOR : '#FF5A5F',
    DEEP_WHITE_PURPLE_COLOR : '#DAD7F9',
    DEEP_GRAY : '#AEB8CC',

    SUB_COLOR : '#EFF2F9',
    SUB_COLOR1 : '',

    FONT_COLOR1 : '#222222',
    FONT_COLOR2 : '#666666',
    FONT_COLOR3 : '#999999',
    FONT_COLOR4 : '#9257FF',

    BORDER_COLOR1 : '#eeeeee',
    BORDER_COLOR2 : '#E9E9E9',
    BORDER_COLOR3 : '#222222',
    BORDER_COLOR4 : '#707070',
    BORDER_COLOR5 : '#D8DCE6',
    BORDER_COLOR_BLACK : '#222222',

    MAIN_BACKGROUND_COLOR1 : '#F1F3F8',
    MAIN_BACKGROUND_COLOR2 : '',
    MAIN_BACKGROUND_COLOR3 : '',
    
    SUB_BACKGROUND_COLOR1 : '#EFF2F9',
    SUB_BACKGROUND_COLOR2 : '#E9E9E9',
    SUB_BACKGROUND_COLOR3 : '#F1E8FF',
    SUB_BACKGROUND_COLOR4 : '#FFDFE7',
    SUB_BACKGROUND_COLOR5 : '#F5F5FE',
    SUB_BACKGROUND_COLOR6 : '#F3F3F3',
    SUB_BACKGROUND_COLOR_PINK : '#FBEBEB',
    SUB_BACKGROUND_COLOR_GRAY_BLUE : '#EEEDFC',
    SUB_BACKGROUND_COLOR_WHITE_GRAY : '#F5F5F5',
    

    ERROR_COLOR1 : '',
    ERROR_COLOR2 : '',
    ERROR_COLOR3 : '',

    SUCCESS_COLOR1 : '',
    SUCCESS_COLOR2 : '',
    SUCCESS_COLOR3 : '',
}
