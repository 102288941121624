import React from 'react';
import * as S from '../../theme/style';
import * as CS from '../ComponentStyle';
import { BarGraphElType, GraphItemType } from '../ComponentCommonType';
import { colors } from '../../theme/color';

export const HorizontalGraphEl = ({item}:BarGraphElType) => {

    const [graphWidth, setGraphWidth] = React.useState(0);

    React.useEffect(()=>{

        setGraphWidth(item.value);

    },[])

    return(
        <S.CustomFlex
            width={'100%'}
            mb={2}
        >
            <S.CustomFlex
                width={'100%'}
                flex_direction='row'
                justify_content='space-between'
                align_items='center'
            >
                <S.CustomPText
                    fs={1.5}
                    fw={'Medium'}
                    fc={colors.FONT_COLOR1}
                >
                    {item.title}
                </S.CustomPText>
                <S.CustomPText
                    fs={1.5}
                    fw={'Bold'}
                    fc={colors.FONT_COLOR1}
                >
                    {item.value}%
                </S.CustomPText>
            </S.CustomFlex>
            <S.CustomDiv
                mt={1}
                height={1.5}
                bgcolor={colors.SUB_BACKGROUND_COLOR1}
                width={'100%'}
            >
                <CS.VerticalGraphFront
                    position={'absolute'}
                    top={0}
                    left={0}
                    height={'100%'}
                    width={`${graphWidth}%`}
                    bgcolor={item.isSelected ? colors.MAIN_COLOR : colors.DEEP_GRAY}
                    style={{transition:'width 0.5s'}}
                />
            </S.CustomDiv>
        </S.CustomFlex>
    )
} 