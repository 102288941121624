import React from 'react';
import * as S from '../../theme/style';
import Modal from 'react-modal';
import './AlertStyle.css';
import { colors } from '../../theme/color';

interface AlertModalType {
    isOpen : boolean,
    close : () => void;
    msg : string,
    errorCode : string,
}

export const AlertModal = ({
    isOpen,
    close,
    msg,
    errorCode,
}:AlertModalType) => {
    return(
        <Modal 
            isOpen={isOpen}
            style={{content:{
                width:'100%',
                height:'100%',
                inset:0,
                padding:0,
                borderRadius:0,
                border:'none',
                backgroundColor:'rgba(0,0,0,0.3)'
            }}}
            
        >
            <S.CustomFlex
                width={'100%'}
                height={'100%'}
                justify_content='center'
                align_items='center'
            >
                <S.CustomDiv
                    width={'80%'}
                    pt={5} pb={5} pl={2} pr={2}
                    bgcolor={colors.WHITE}
                    br={2}
                >
                    <S.CustomPText
                        text_align='center'
                        fs={1.6}
                        fw={'Medium'}
                        fc={colors.FONT_COLOR1}
                        mb={2}
                    >
                        error code : {errorCode}
                    </S.CustomPText>
                    <S.CustomPText
                        text_align='center'
                        fs={1.6}
                        fw={'Medium'}
                        fc={colors.FONT_COLOR1}
                    >
                        {msg}
                    </S.CustomPText>

                    <S.MarginCom mb={4} />

                    <S.CustomDiv
                        width={'100%'}
                        pt={2} pb={2}
                        bgcolor={colors.MAIN_COLOR}
                        br={1}
                        onClick={()=>{
                            window.parent.postMessage('historyBack');
                            close();
                        }}
                    >
                        <S.CustomPText
                            text_align='center'
                            fs={1.7}
                            fc={colors.WHITE}
                            fw={'Medium'}
                        >
                            확인
                        </S.CustomPText>
                    </S.CustomDiv>
                </S.CustomDiv>
            </S.CustomFlex>
        </Modal>
    )
}