import React from 'react'
import * as S from '../theme/style'
import { colors } from '../theme/color'
import { useLocation, useNavigate } from 'react-router'
import { timeWithDateConverter } from '../utils/func'
import { useUserInfoStore } from '../store'
import { useGetMutation, usePutMutation } from '../hook/ReactQueryHook'
import { AlertModal } from '../component/Modal/AlertModal'
import { SurveyListCardItemType } from './CommonScreenType'

export const SurveyDetail = () => {
  const { memberId, token, refreshToken, updateUserInfo } = useUserInfoStore((state) => state)

  const navigation = useNavigate()
  const location = useLocation()
  const { surveyItem , pageInfo } = location.state //리스트에서 가져온 해당 설문 정보 (page 정보 없음)

  const getSurveyMutation = useGetMutation(
    'getSurveyMutation',
    `/surveys/${surveyItem.surveyId}`
  )
  const joinSurveyMutation = usePutMutation('joinSurvey') //서베이 참여

  const [surveyInfo, setSurveyInfo] = React.useState<SurveyListCardItemType>()

  const [alertModal, setAlertModal] = React.useState({
    alert: false,
    errorCode: '',
    msg: '',
  })

  const getSurveyInfoHandler = async () => {
    const params = {
      postData: {},
      headConfig: {
        memberId: memberId,
        Authorization: `Bearer ${token}`,
        refreshToken: `Bearer ${refreshToken}`,
      },
    }

    await getSurveyMutation
      .mutateAsync(params)
      .then((res) => {
        console.log(res)
            const { code, data, msg, refreshInfo } = res;

            if (refreshInfo) {
                updateUserInfo({
                    accessTime: new Date(),
                    memberId: refreshInfo.memberId,
                    token: refreshInfo.tokenInfo.accessToken,
                    refreshToken: refreshInfo.tokenInfo.refreshToken,
                    isNewMember: refreshInfo.isNewMember,
                })
            }
            if (code === 'SUCCESS') {
                console.log(surveyItem)
                setSurveyInfo(data.survey)
            } else {
            }
      })
      .catch((err) => {
        const { errCode, errMsg } = err.response.data

        setAlertModal({
          alert: true,
          errorCode: errCode,
          msg: errMsg,
        })
      })

    // const {code,data} = resData
  }

    /**서베이 참여하기 */
  const joinSurveysHandler = async () => { 
    const putUrl = `/surveys/${surveyItem.surveyId}/join`
    const params = {
      apiUrl: putUrl,
      headConfig: {
        memberId: memberId,
        Authorization: `Bearer ${token}`,
        refreshToken: `Bearer ${refreshToken}`,
      },
    }
    console.log(memberId, token)

    await joinSurveyMutation
      .mutateAsync(params)
      .then((res) => {
        if (res) {
            const { code, data, refreshInfo } = res
            
            if (refreshInfo) {
              updateUserInfo({
                accessTime: new Date(),
                memberId: refreshInfo.memberId,
                token: refreshInfo.tokenInfo.accessToken,
                refreshToken: refreshInfo.tokenInfo.refreshToken,
                isNewMember: refreshInfo.isNewMember,
              })
            };

          const { action, subData } = data

          if (code && code === 'SUCCESS') {
            console.log(action, subData);
            if (action === 'move') {
              navigation(`/SurveyProgress?pageurl=${subData.pageurl}`, { state: { surveyItem: surveyInfo } });
            }
          } else {
          }
        }
      })
      .catch((err) => {
        const { errCode, errMsg } = err.response.data

        setAlertModal({
          alert: true,
          errorCode: errCode,
          msg: errMsg,
        })
      })
    // console.log(code,data);
  }

  const moveSurveyProgress = (state: string) => {
    if (state === 'next') {
      joinSurveysHandler()
    }
    else if (state === 'result') { 
      navigation(`/SurveyQuickResult?surveyId=${surveyItem.surveyId}`);
    }
    else if (state === 'goBack') {
      navigation(-1)
    }
  }

  React.useEffect(() => {
    getSurveyInfoHandler();
    console.log(pageInfo);
  }, [])

  return (
    <S.CustomDiv height={'100vh'}>
      <AlertModal
        isOpen={alertModal.alert}
        msg={alertModal.msg}
        errorCode={alertModal.errorCode}
        close={() => {
          setAlertModal({ alert: false, msg: '', errorCode: '' })
        }}
      />
      <S.CustomDiv
        bgcolor={colors.SUB_BACKGROUND_COLOR1}
        pt={3}
        pb={3}
        pl={2}
        pr={2}
      >
        {/** 설문타입 */}
        <S.CustomPText
          fs={1.3}
          fc={colors.FONT_COLOR3}
          text_align={'center'}
          mb={1}
        >
          {surveyItem.type === 'SURVEY'
            ? `일반설문(총 ${surveyItem.totalQuestionCount}문항)`
            : `Quick Survey(총 ${surveyItem.totalQuestionCount}문항)`}
          {surveyInfo?.userJoinStatus === 'REWARDED' && ' | 설문완료'}
        </S.CustomPText>

        {/** 설문 제목 */}
        <S.CustomPText
          fs={2}
          fc={colors.FONT_COLOR1}
          fw={'Bold'}
          text_align={'center'}
          mb={1}
        >
          {surveyItem.introTitle}
        </S.CustomPText>

        {/** 설문 기간 */}
        <S.CustomPText
          fs={1.3}
          fc={colors.FONT_COLOR3}
          text_align={'center'}
          mb={0.5}
        >
          {timeWithDateConverter(surveyItem.planOpenDtti).stringDate} ~{' '}
          {timeWithDateConverter(surveyItem.planCloseDtti).stringDate}
        </S.CustomPText>

        {/** 설문 기간 종료 */}
        {surveyItem.status !== 'OPEN' && (
          <S.CustomPText
            fs={1.3}
            fc={colors.WHITE_RED_COLOR}
            text_align={'center'}
            mb={1}
          >
            설문조사 기간이 만료되었습니다.
          </S.CustomPText>
        )}
        {/** 설문 해시태그 */}
        <S.CustomDiv mt={1}>
          {surveyItem.tags.map((item: string, index: number) => {
            return (
              <S.CustomSpanText
                key={index}
                fs={1.4}
                fc={colors.SKY_BLUE_COLOR}
                ml={1}
                mr={1}
              >
                #{item}
              </S.CustomSpanText>
            )
          })}
        </S.CustomDiv>

        {/** 적립 포인트 */}
        {surveyItem.basicRewardPoint && surveyItem.basicRewardPoint !== '' && (
          <S.CustomDiv
            bgcolor={colors.MAIN_COLOR}
            br={8.5}
            mt={2}
            pt={1}
            pb={1}
            pl={1}
            pr={1.5}
            display={'inline-block'}
          >
            <S.CustomFlex flex_direction="row" align_items="center">
              <S.CustomImg
                width={2.1}
                height={2.1}
                src={require('../assets/img/ico_point2.png')}
                mr={1}
              />
              <S.CustomSpanText fs={1.3} fc={colors.WHITE} fw={'Medium'}>
                {surveyItem.basicRewardPoint}P 적립
              </S.CustomSpanText>
            </S.CustomFlex>
          </S.CustomDiv>
        )}
      </S.CustomDiv>
      <S.MarginCom mt={2} />
      <S.ContainerXl>
        <S.CustomDiv pb={10}>
          <S.CustomImg
            br={1}
            width={'100%'}
            src={surveyItem.introImg ? `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_HOST_VER}${surveyItem.introImg}` : require('../assets/img/banner1.png')}
          />
          <S.CustomPText fs={1.4} fw={'Light'} fc={colors.FONT_COLOR2} mt={3}>
            {surveyItem.desc}
          </S.CustomPText>
          <S.CustomDiv
            bgcolor={colors.SUB_BACKGROUND_COLOR_WHITE_GRAY}
            br={1}
            pa={2}
            mt={3}
          >
            <S.CustomPText fs={1.3} fc={colors.FONT_COLOR2} fw={'Light'}>
              조사된 귀하의 의견은{' '}
              <S.CustomSpanText
                fc={colors.MAIN_COLOR}
                style={{ textDecoration: 'underline' }}
              >
                연구목적 외에 타용도로는 사용하지 않습니다.
              </S.CustomSpanText>
              <br />본 조사는{' '}
              <S.CustomSpanText fc={colors.WHITE_RED_COLOR}>
                선착순
              </S.CustomSpanText>
              으로 진행되며, 선착순 마감 시 조기 종료될 수 있습니다.
              <br />
              본 조사는 해당 도로이용객을 대상으로 하는 조사로서 도로이용객이
              아닌 경우 조기 종료될 수 있습니다.
              <br />
              조기 종료시 답례품은 지급되지 않습니다.
            </S.CustomPText>
          </S.CustomDiv>
          {/* <button
                        onClick={()=>{
                            setAlertModal({
                                alert:true,
                                msg: 'asdfasdf',
                            })
                        }}
                    >dddddddddddd</button> */}
        </S.CustomDiv>
      </S.ContainerXl>
      <S.CustomDiv width={'100%'} position={'fixed'} bottom={0}>
        {surveyItem.status === 'QUATA_FULL' && (
          <S.CustomFlex
            bgcolor={colors.SUB_BACKGROUND_COLOR3}
            width={'100%'}
            flex_direction={'row'}
            justify_content={'space-between'}
            align_items={'center'}
            onClick={() => {
              moveSurveyProgress('goBack')
            }}
            pt={1}
            pb={1}
          >
            <S.CustomFlex flex_direction={'row'} align_items={'center'} pl={4}>
              <S.CustomImg
                width={4}
                height={4}
                src={require('../assets/img/alim_img01.png')}
                mr={0.5}
              />
              <S.CustomSpanText fs={1.4} fc={colors.FONT_COLOR4}>
                해당 설문 조사의 참여가 마감되었습니다.
              </S.CustomSpanText>
            </S.CustomFlex>
            <S.CustomImg
              width={2}
              height={2}
              pr={4}
              src={require('../assets/img/ico_close.png')}
            />
          </S.CustomFlex>
        )}
        {surveyItem.status === 'OPEN' &&
          surveyItem.userJoinStatus === 'REWARDED' && (
            <S.CustomFlex
              bgcolor={colors.SUB_BACKGROUND_COLOR4}
              width={'100%'}
              pt={1}
              pb={1}
              flex_direction={'row'}
              justify_content={'space-between'}
              align_items={'center'}
            >
              <S.CustomFlex
                flex_direction={'row'}
                align_items={'center'}
                pl={4}
              >
                <S.CustomImg
                  width={4}
                  height={4}
                  src={require('../assets/img/alim_img02.png')}
                  mr={0.5}
                />
                <S.CustomSpanText fs={1.4} fc={colors.WHITE_RED_COLOR}>
                  이미 작성한 설문조사입니다.
                </S.CustomSpanText>
              </S.CustomFlex>
              <S.CustomDiv
                br={15}
                bgcolor={colors.WHITE_RED_COLOR}
                pt={1}
                pb={1}
                pl={2}
                pr={2}
                mr={4}
                onClick={() => {
                  moveSurveyProgress('goBack')
                }}
              >
                <S.CustomSpanText fs={1.4} fc={colors.WHITE}>
                  돌아가기
                </S.CustomSpanText>
              </S.CustomDiv>
            </S.CustomFlex>
          )}
        {surveyItem.status === 'OPEN' &&
          (surveyItem.userJoinStatus === 'NOT_JOINED' ||
            (surveyItem.type === 'POLL' &&
              surveyItem.userJoinStatus === 'REWARDED')) && (
            <S.CustomFlex
              bgcolor={colors.MAIN_COLOR}
              width={'100%'}
              pt={2.5}
              pb={2.5}
              bottom={0}
              justify_content={'center'}
              align_items={'center'}
              onClick={() => {
                moveSurveyProgress(
                  surveyItem.userJoinStatus === 'REWARDED' &&
                    surveyItem.type === 'POLL'
                    ? 'result'
                    : 'next'
                )
              }}
            >
              <S.CustomSpanText fs={1.8} fw={'Bold'} fc={colors.WHITE}>
                {surveyItem.userJoinStatus === 'REWARDED' &&
                surveyItem.type === 'POLL'
                  ? '결과 확인하기'
                  : '참여하기'}
              </S.CustomSpanText>
            </S.CustomFlex>
          )}
      </S.CustomDiv>
    </S.CustomDiv>
  )
}
