import React from 'react'
import './App.css'
import { Router } from './navigation/Router'
import { Chart, ArcElement, registerables } from 'chart.js'
import Modal from 'react-modal'
import { usePostMutation } from './hook/ReactQueryHook'
import { useUserInfoStore } from './store'
import Toast from './component/toast/ToastConfig'
import { AlertModal } from './component/Modal/AlertModal'
import { useLocation } from 'react-router'

Modal.setAppElement('#root')

function App() {
  Chart.register(ArcElement, ...registerables)

  const { updateUserInfo } = useUserInfoStore((state) => state)

  const queryParams = new URLSearchParams(window.location.search)
  const tParam = queryParams.get('tParam')
  const getTokenMutation = usePostMutation('getToken', '/auth/token') //토큰발급 a
  const [alertModal, setAlertModal] = React.useState({
    alert: false,
    errorCode: '',
    msg: '',
  })

  /** 토큰 발급 */
  const getTokentHandler = async () => {
    const params = {
      postData: {
        tParam:
          tParam || '51435EF63B3DE785B6808AB8B450AF6DECC43A7D57C4B2EAB1702C952D93E86372A338A420ADFFE69404D638389E7BF2C23EC40A47AED7CBAD4DEDE776F21612786D68AFFB6211C572312A098ECA3DE38B1DBDA12541FC1BC94CFA2DFC01A94EC7C352B427DF438B93A163814C612810CC144E47546416270EA3BC7644DEEE58FB700941467195753D435825C02E8DF0',
      },
      headConfig: {
        'Content-Type': 'multipart/form-data',
      },
    }

    alert(`tparam ?? ${tParam}`)

    await getTokenMutation
      .mutateAsync(params)
      .then((res) => {
        const { data, code, msg } = res

        if (code === 'SUCCESS') {
          updateUserInfo({
            accessTime: new Date(),
            memberId: data.memberId,
            token: data.tokenInfo.accessToken,
            refreshToken: data.tokenInfo.refreshToken,
            isNewMember: data.isNewMember,
          })
        } else {
          //예외상황 발생
        }
      })
      .catch((err) => {
        const { errCode, errMsg } = err.response.data
        setAlertModal({
          alert: true,
          errorCode: errCode || '',
          msg: errMsg || '예기치 못한 오류가 발생하였습니다.',
        })
      })
  }

  React.useEffect(() => {
    getTokentHandler()

    const height = window.innerHeight
    window.parent.postMessage(height, '*')

    // /**  add backbutton handler event */
    // window.addEventListener('backButtonHandler', () => alert('test'))

    // /**  remove backbutton handler event - 웹앱 종료 */
    // return () => window.removeEventListener('backButtonHandler', () => alert('test'))

    const testBackHandler = (event : any) => { 
      if (event.data === 'backButtonHandler') {
        // console.log('data ? ', event.data)
        alert(event.data);
      }
    }

    window.addEventListener('message', (event) => {
      testBackHandler(event);
    });

    return () => window.removeEventListener('message', (event) => { testBackHandler(event) });
  }, [])

  return (
    <div className="App">
      <Router />
      <Toast />
      <AlertModal
        isOpen={alertModal.alert}
        msg={alertModal.msg}
        errorCode={alertModal.errorCode}
        close={() => {
          setAlertModal({ alert: false, msg: '', errorCode: '' })
        }}
      />
    </div>
  )
}

export default App
