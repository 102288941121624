import React from 'react';

import * as S from '../../theme/style';
import * as CS from '../ComponentStyle';

import { colors } from '../../theme/color';
import { styled } from 'styled-components';
import { BarGraphElType, GraphItemType } from '../ComponentCommonType';





export const BarGraphEl = ({item,itemArray}:BarGraphElType) => { //세로막대

    const [graphHeight,setGraphHeight] = React.useState(0);


    React.useEffect(()=>{
        if(itemArray){
            const maxTempArray = itemArray?.map((mItem,index) => {
                return mItem.value;
            })

            let maxValue = Math.max.apply(Math,maxTempArray);

            const perValue = (100/maxValue)*item.value;
            setGraphHeight(perValue);
        }
    },[])

    return(
        <S.CustomDiv
            flex={1}
        >
            <S.CustomFlex
                height={20}
                flex={1}

                // bgcolor={colors.FONT_COLOR1}
                justify_content='flex-end'
                align_items='center'
            >
                <CS.BarGraphFront
                    // width={'80%'}
                    width={'70%'}
                    height={`${graphHeight.toFixed(1)}`}
                    br={1}
                    bw={0.15}
                    bc={item.isSelected ? colors.MAIN_COLOR : 'transparent'}
                    bgcolor={item.isSelected ? colors.DEEP_WHITE_PURPLE_COLOR : colors.SUB_BACKGROUND_COLOR1}
                    flex={1}
                    // style={{maxWidth:'80%'}}
                    
                />
            </S.CustomFlex>
            <S.CustomPText
                fs={1.3}
                fc={item.isSelected ? colors.MAIN_COLOR : colors.FONT_COLOR1}
                fw={'Bold'}
                mt={1}
                text_align='center'
            >
                {item.title}
            </S.CustomPText>
            <S.CustomPText
                fs={1.3}
                fc={item.isSelected ? colors.MAIN_COLOR : colors.FONT_COLOR1}
                fw={'Light'}
                mt={1}
                text_align='center'
            >
                {item.value}%
            </S.CustomPText>
        </S.CustomDiv>
    )
}