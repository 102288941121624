import React from 'react';
import * as S from '../../theme/style';
import { styled } from 'styled-components';
import { colors } from '../../theme/color';
import { SurveySelImageType } from '../ComponentCommonType';
import Modal from 'react-modal';
import { ImageDetailViewModal } from '../Modal/ImageDetailViewModal';

const GridWrapper = styled(S.CustomDiv)`
    display : grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap:0.5rem;
`

export const SurvayImgSoloSel = ({ //이미지 선택 하나만
    selValue,
    selectImgHandler,
    choiceItem,
    perArray,
    isResult,
    questionId,
    maxSelect,
    answerList,
}:SurveySelImageType) => {

    const [detailViewModal, setDetailViewModal] = React.useState<boolean>(false);
    const [selImgLink, setSelImgLink] = React.useState<string>('');
    const [isSel, setIsSel] = React.useState(false);

    React.useEffect(()=>{
        // console.log(selValue);
        if(answerList){
            setIsSel(answerList?.some(el => el.choiceId === selValue));
        }   
    },[selValue])

    return(
        <>
            <S.CustomDiv 
                // key={index}
                onClick={()=>{

                    if(questionId && maxSelect && selectImgHandler){

                        console.log(choiceItem.value);
                        selectImgHandler(choiceItem.value,questionId,choiceItem.choiceId,false,true,maxSelect)
                    }
                }}
                br={2}
                bw={0.2}
                bc={isSel ? colors.MAIN_COLOR : 'transparent'}
                style={{overflow:'hidden'}}
            >
                <S.CustomImg 
                    width={'100%'}
                    height={'100%'}
                    src={`${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_HOST_VER}${choiceItem.img}`}
                />
                <S.CustomFlex
                    position={'absolute'}
                    top={1}
                    left={1}
                    br={100}
                    width={'25%'}
                    height={'25%'}
                    bgcolor={isSel ? colors.MAIN_COLOR : 'rgba(255,255,255,0.8)'}
                    bw={0.1}
                    bc={isSel ? 'transparent' : colors.BORDER_COLOR3}
                    justify_content='center'
                    align_items='center'
                >
                    {isSel &&
                        <S.CustomImg
                            width={'60%'}
                            height={'40%'}
                            src={require('../../assets/img/ico_check.png')}
                        />
                    } 
                </S.CustomFlex>
                <S.CustomDiv
                    position={'absolute'}
                    bottom={1}
                    right={1}
                    width={!perArray ? '25%' : undefined}
                    height={'25%'}
                    onClick={(e)=>{
                        if(!isResult){
                            e.stopPropagation();
                            setDetailViewModal(true);
                            setSelImgLink(`${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_HOST_VER}${choiceItem.img}`);
                        }
                    }}
                >
                    {!isResult? 
                        <S.CustomImg 
                            width={'100%'}
                            height={'100%'}
                            src={require('../../assets/img/ico_expand.png')}
                        />
                        :
                        <S.CustomDiv
                            br={15}
                            bgcolor='rgba(34,34,34,0.8)'
                            pt={0.5} pb={0.5} pl={1} pr={1}
                        >
                            <S.CustomPText
                                fs={1.3}
                                fc={colors.WHITE}
                                fw={'Light'}
                            >
                                {/* {perArray && perArray[index].toFixed(1)}% */}
                            </S.CustomPText>
                        </S.CustomDiv>
                    }
                </S.CustomDiv>
            </S.CustomDiv>
            <ImageDetailViewModal 
                isOpen={detailViewModal}
                onClose={()=>{setDetailViewModal(false)}}
                imgLink={selImgLink}
            />
            </>
    )
}