import React from 'react';
import * as S from '../../theme/style';
import { colors } from '../../theme/color';

interface IntroTitleType {
    ment : string;
}

export const IntroContents = ({ment}:IntroTitleType) => {
    return(
        <S.CustomDiv mt={2}>
            <S.CustomFlex
                flex_direction='row'
            >
                <S.CustomDiv
                    flex={0.3}
                >
                    <S.CustomDiv
                        mt={0.6} 
                        width={0.5}
                        height={0.5}
                        br={999}
                        bgcolor={colors.MAIN_COLOR}
                    />
                </S.CustomDiv>
                <S.CustomDiv
                    flex={8}
                >
                    <S.CustomPText
                        fs={1.5}
                        fc={colors.FONT_COLOR2}
                        fw={'Light'}
                    >
                    {ment}
                    </S.CustomPText>
                </S.CustomDiv>
            </S.CustomFlex>
        </S.CustomDiv>
    )
}