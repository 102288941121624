import React from 'react';
import * as S from '../../theme/style';
import { SurveyRadioCustomType } from '../ComponentCommonType';
import { colors } from '../../theme/color';
import { styled } from 'styled-components';



export const SurveyRadioCustom = ({ //설문 라디오 선택(단일 및 다중 선택)
    radioArray,
    radioValue,
    selValue,
    setSelSurvey,
    maxSelect,
    questionId,
    title,
    isMultiple,
    choiceId,
    answerList,
    maxLength,
    cIndex,
    type,
} : SurveyRadioCustomType) => {

    const [isSel, setIsSel] = React.useState(false);

    const [ortherText, setOrtherText] = React.useState('');

    /** 항목 선택 handler (단일 및 다중 선택) */
    const selSurbeyHandler = (text?:string) => {
        if(isMultiple && maxSelect){ //여러항목 선택가능
            // console.log(text);
            if(text)setOrtherText(text);
            setSelSurvey(text || radioValue,questionId,choiceId,text ? true : false ,isMultiple,maxSelect);
        }
        else{ //단일항목 선택가능
            setSelSurvey(radioValue,questionId,choiceId,false,isMultiple);
            return false;
        }
    }

    React.useEffect(()=>{
        // console.log(selValue);
        if(isMultiple){ //답변이 여러개일때는 id로 선택구분
            if(answerList){
                setIsSel(answerList?.some(el => el.choiceId === selValue));
            }
        }
        else{
            setIsSel(selValue === radioValue);
        }
    },[selValue])
    
    return(
        <>
            <S.CustomFlex
                br={1}
                bw={isSel? 0.2 : 0.1}
                bc={isSel ? colors.MAIN_COLOR : colors.BORDER_COLOR2}
                pt={isSel ? 1.4 : 1.5} pb={isSel ? 1.4 : 1.5} pl={isSel ? 2.4 : 2.5} pr={isSel ? 2.4 : 2.5}
                flex_direction={'row'}
                justify_content={'space-between'}
                align_items={'center'}
                height={'100%'}
                style={{boxSizing:'border-box',gridColumnStart:cIndex%2 === 0 ? 1 : 2,gridColumnEnd:type === 'FORM_LONG' ? 3 : cIndex%2 === 0 ? 2 : 3}}
                mb={1}
                onClick={()=>{
                    if(radioValue === null){
                        selSurbeyHandler(ortherText);
                    }
                    else{
                        selSurbeyHandler();
                    }
                }}
            >
                <S.CustomSpanText 
                    fs={1.5}
                    fc={isSel ? colors.MAIN_COLOR : colors.FONT_COLOR3}
                    fw={isSel ? 'Bold' : 'Regular'}
                >
                    {title}
                </S.CustomSpanText>

                <S.CustomDiv>
                    <S.CustomFlex
                        background={isSel ? 'transparent linear-gradient(180deg, #926BFF 0%, #4737DF 100%) 0% 0% no-repeat padding-box' : undefined}
                        bgcolor={!isSel ? colors.SUB_BACKGROUND_COLOR2 : undefined}
                        width={2.8}
                        height={2.8}
                        br={999}
                        justify_content='center'
                        align_items='center'
                    >
                        <S.CustomDiv 
                            bgcolor={colors.WHITE}
                            br={999}
                            width={1.2}
                            height={1.2}
                        />
                    </S.CustomFlex>
                </S.CustomDiv>
            </S.CustomFlex>
            {(isSel && (type === 'FORM_SHORT')) &&
                <S.CustomDiv
                    pa={2}
                    bw={0.1}
                    bc={colors.BORDER_COLOR2}
                    br={1}
                    style={{gridColumnStart:1, gridColumnEnd:3}}
                    mb={1}
                >
                    <S.CustomTextInput
                        width={'100%'}
                        bw={0}
                        placeholder={`직업 입력하세요. 최대 ${maxLength}글자 이내`}
                        fs={1.5}
                        ph_color={colors.FONT_COLOR3}
                        fc={colors.FONT_COLOR1}
                        // style={{minHeight:'15rem'}}
                        maxLength={maxLength}
                        onChange={(e)=>{
                            selSurbeyHandler(e.target.value);
                        }}
                    >

                    </S.CustomTextInput>
                </S.CustomDiv>
            }
        </>
    )


}
