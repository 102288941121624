import React from 'react';
import * as S from '../../theme/style';
import { colors } from '../../theme/color';

interface IntroTitleType {
    ment : string;
}

export const IntroTitle = ({ment}:IntroTitleType) => {
    return(
        <S.CustomFlex
            flex_direction='row'
            align_items='center'
        >
            <S.CustomImg 
                width={2}
                src={require('../../assets/img/ico_alim.png')}
            />
            <S.CustomSpanText
                ml={0.5}
                fs={1.8}
                fc={colors.MAIN_COLOR}
                fw={'Bold'}
            >
                {ment}
            </S.CustomSpanText>
        </S.CustomFlex>
    )
}