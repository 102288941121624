import React from 'react';
import * as S from '../../theme/style';
import { SurveyTextInputType } from '../ComponentCommonType';
import { colors } from '../../theme/color';
import { NumberComma, phoneAddHipone } from '../../utils/func';

export const SurveyTextInput = ({
    inputText, //입력한 value
    setInputText, //input handler
    type, //choice 타입
    questionId, //질문 id
    choiceId, //문항 id
    maxLength  //입력 최대길이
}:SurveyTextInputType) => {


    const inputHandler = (text:string) => {
        setInputText(text,questionId,choiceId,false);
    }
    

    return (
      <S.CustomDiv
      // width={'100%'}
      >
        {type !== 'FORM_LONG' ? (
          <S.CustomTextInput
            width={'100%'}
            type={'text'}
            placeholder={type === 'FORM_PHONE' ? '직접 입력하세요. (숫자만)' : type === 'FORM_YYYYMMDD' ? '직접입력하세요. (19000101)' : "직접 입력하세요."}
            bw={0}
            bbw={0.15}
            bbc={colors.BORDER_COLOR_BLACK}
            // pt={2}
            pb={2}
            fc={colors.FONT_COLOR1}
            ph_color={colors.FONT_COLOR3}
            fs={1.5}
            br={0}
            value={inputText || ''}
            maxLength={
              type === 'FORM_PHONE'
                ? 13
                : type === 'FORM_YYYYMMDD'
                ? 8
                : maxLength
            }
            onChange={(e) => {
              if (type === 'FORM_INTEGER') {
                const numberText = e.target.value.replaceAll(',', '').trim()
                let check = /^[0-9]+$/
                if (!check.test(numberText) && numberText !== '') {
                  return false
                }
                const commaNumber = NumberComma(Number(numberText))
                inputHandler(commaNumber === '0' ? '' : commaNumber)
              }
              else if (type === 'FORM_YYYYMMDD') { 
                  const numberText = e.target.value.replaceAll(',', '').trim()
                  let check = /^[0-9]+$/
                  if (!check.test(numberText) && numberText !== '') {
                    return false
                  }
                  inputHandler(e.target.value);
              }
              else if (type === 'FORM_PHONE') {
                // console.log(phoneAddHipone(e.target.value));
                inputHandler(phoneAddHipone(e.target.value))
              } else {
                inputHandler(e.target.value.trim())
              }
            }}
          />
        ) : (
          <S.CustomDiv
            pa={2}
            bw={0.1}
            bc={colors.BORDER_COLOR2}
            br={2}
            style={{ gridColumnStart: 1, gridColumnEnd: 3 }}
          >
            <S.CustomTextArea
              width={'100%'}
              bw={0}
              placeholder={`직접 입력하세요. 최대 ${maxLength}글자 이내`}
              fs={1.5}
              ph_color={colors.FONT_COLOR3}
              fc={colors.FONT_COLOR1}
              style={{ minHeight: '15rem' }}
              maxLength={maxLength}
              onChange={(e) => {
                inputHandler(e.target.value)
              }}
            ></S.CustomTextArea>
          </S.CustomDiv>
        )}
      </S.CustomDiv>
    )
}