import React from 'react'
import * as S from '../../theme/style'
import { SurveyRadioCustom } from '../../component/List/SurveyRadioCustom'
import { colors } from '../../theme/color'
import { SurveySelectBox } from '../../component/List/SurveySelectBox'
import { SurveyDotSelect } from '../../component/List/SurveyDotSelect'
import { ProgressBar } from '../../component/progress/ProgressBar'
import { SurvayImgSoloSel } from '../../component/List/SurvayImgSoloSel'
import { styled } from 'styled-components'
import { useLocation, useNavigate, useParams } from 'react-router'
import { SurveyStarSelect } from '../../component/List/SurveyStarSelect'
import { useGetMutation, usePostMutation } from '../../hook/ReactQueryHook'
import { useUserInfoStore } from '../../store'
import {
  AnswerListType,
  QuestionItemType,
  QuestionListType,
} from '../CommonScreenType'
import { SurveyTextInput } from '../../component/List/SurveyTextInput'
import { SurveyRankingList } from '../../component/List/SurveyRankingList'
import { SurveyTextArea } from '../../component/List/SurveyTextArea'
import { showToast } from '../../component/toast/ToastConfig'
import { AlertModal } from '../../component/Modal/AlertModal'

const GridWrapper = styled(S.CustomDiv)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.5rem;
`
const ImageGridWrapper = styled(S.CustomDiv)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.5rem;
`

export const SurveyProgress = () => {
  const navigation = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const pageurl = queryParams.get('pageurl')
  const { surveyItem } = location.state

  const { memberId, token, refreshToken ,updateUserInfo } = useUserInfoStore((state) => state)
  const [surveyProgress, setSurveyProgress] = React.useState(0) //설문 진행도

  const [pageUrl, setPageUrl] = React.useState<string>('') //질문지를 반환받을 수 있는 pageurl

  const [questionInfo, setQuestionInfo] = React.useState<QuestionListType>()
  const [questionList, setQuestionList] = React.useState<QuestionItemType[]>([])
  const [answerList, setAnswerList] = React.useState<AnswerListType[]>([])

  const [alertModal, setAlertModal] = React.useState({
    //알림창
    alert: false,
    errorCode: '',
    msg: '',
  })

  const [page, setPage] = React.useState(0)
  const [totalPage, setTotalPage] = React.useState(1)

  const getSurveyQuestionMutation = useGetMutation(
    'getSurveyQuestion',
    `${pageUrl}`
  ) //설문정보 가져오기
  const nextQuestionMutation = usePostMutation(
    'nextQuestion',
    `${pageUrl}/post_nextpage`
  )

  /** type :  TEXT_FORM , INDEX_STAR */
  const setInputSurvey = (
    value: string,
    questionId: string,
    choiceId: string
  ) => {
    let filterAnswer = answerList.filter((el) => el.questionId !== questionId)

    const answerData = {
      questionId: questionId,
      value: value,
    }
    filterAnswer.push(answerData)

    setAnswerList([...filterAnswer])
  }

  /** Radio 설문 선택 */
  const setSelSurvey = (
    value: string, //선택한 문항의 데이터 (텍스트일경우 텍스트 데이터)
    questionId: string, //질문ID
    choiceId: string, //문항 ID
    isText: boolean = false, //텍스트유형인지 판단
    isMultiple: boolean, //다중선택 판단
    maxSelect?: number //최대 선택값
  ) => {
    //설문 선택

    if (isMultiple) {
      //설문 선택지가 여러개일경우
      let filterAnswer = answerList.filter((el) => el.questionId === questionId) //해당질문을 선택했는지 판단
      const saveAnswer = answerList.filter((el) => el.questionId !== questionId) //해당질문을 제외한 기존데이터

      if (filterAnswer.length === 0) {
        //선택한 질문이 없을때
        filterAnswer.push({
          questionId: questionId,
          answers: [{ choiceId: choiceId, value: value }],
        })
        setAnswerList([...saveAnswer, ...filterAnswer])
      } else {
        let choiceFilter = filterAnswer[0].answers?.filter(
          (el) => el.choiceId === choiceId
        ) //선택한값
        let beforeChoiceFilter = filterAnswer[0].answers?.filter(
          (el) => el.choiceId !== choiceId
        ) //기선택되어있는 값 (다시선택시 선택해제)

        const answerPush = () => {
          filterAnswer[0].answers?.push({
            choiceId: choiceId,
            value: value,
          })
        }
        if (choiceFilter && beforeChoiceFilter) {
          if (!isText) {
            //기타 선택x (일반 문항 선택)
            if (choiceFilter.length === 0) {
              //기선택한 동일한 문항이 없을경우
              if (maxSelect) {
                //최대 선택 판단
                if (maxSelect === 1 && filterAnswer[0].answers) {
                  filterAnswer[0].answers[0] = {
                    choiceId: choiceId,
                    value: value,
                  }
                } else {
                  if (beforeChoiceFilter.length === maxSelect) {
                    // filterAnswer[0].answers = [...beforeChoiceFilter,{choiceId:choiceId,value : value}];
                    return false
                  } else {
                    answerPush()
                  }
                }
              } else {
                //최대선택이 없을경우 그냥 넣음
                answerPush()
              }
            } else {
              filterAnswer[0].answers = [...beforeChoiceFilter]
            }
          } else {
            //기타 선택시 적용 (텍스트 입력)
            if (choiceFilter.length > 0) {
              //기존에 기타문항을 선택했을때
              if (choiceFilter[0].value === value) {
                //입력된값과 저장되어있는 값이 같을 경우 선택해제
                filterAnswer[0].answers = [...beforeChoiceFilter]
              } else {
                //입력된값과 저장되어있는값이 다를때 새로 저장
                filterAnswer[0].answers = [
                  ...beforeChoiceFilter,
                  { choiceId: choiceId, value: value },
                ]
              }
            } else {
              //기존에 기타문항을 선택하지 않았을 경우
              if (filterAnswer[0].answers && maxSelect) {
                //선택
                if (filterAnswer[0].answers?.length >= maxSelect) {
                  filterAnswer[0].answers = [
                    ...beforeChoiceFilter,
                    { choiceId: choiceId, value: value },
                  ]
                } else {
                  answerPush()
                }
              }
            }
          }
          setAnswerList([...saveAnswer, ...filterAnswer])
        }
      }
    } else {
      let filterAnswer = answerList.filter((el) => el.questionId !== questionId)

      const answerData = {
        questionId: questionId,
        value: value,
      }
      filterAnswer.push(answerData)

      setAnswerList([...filterAnswer])
    }
  }

  /** 드랍박스 value 핸들러 */
  const dropBoxHandler = (
    value: string,
    choiceId: string,
    questionId: string
  ) => {
    let filterAnswer = answerList.find((el) => el.questionId === questionId) //해당질문을 선택했는지 판단

    // console.log(filterAnswer)

    if (filterAnswer) {
      //   console.log(filterAnswer)
      let filterDropList = answerList.filter(
        (el) => el.questionId !== questionId
      )

      filterDropList.push({
        questionId: questionId,
        answers: [
          {
            choiceId: choiceId,
            value: value,
          },
        ],
      })

      setAnswerList([...filterDropList])
    } else {
      //   console.log(answerList)
      let beforeAnswerList = [...answerList]

      beforeAnswerList.push({
        questionId: questionId,
        answers: [
          {
            choiceId: choiceId,
            value: value,
          },
        ],
      })

      setAnswerList([...beforeAnswerList])
    }
  }

  const getSurveyQuetionHandler = async () => {
    //서베이 질문지 반환
    try {
      const params = {
        postData: {},
        headConfig: {
          memberId: memberId,
          Authorization: `Bearer ${token}`,
          refreshToken: `Bearer ${refreshToken}`,
        },
      }
      //   console.log('params ?', params)

      await getSurveyQuestionMutation
        .mutateAsync(params)
        .then((res) => {
            const { code, msg, data, refreshInfo} = res //refresh를 했을때

          console.log(code, msg, data);
          
            if (refreshInfo) {
              updateUserInfo({
                accessTime: new Date(),
                memberId: refreshInfo.memberId,
                token: refreshInfo.tokenInfo.accessToken,
                refreshToken: refreshInfo.tokenInfo.refreshToken,
                isNewMember: refreshInfo.isNewMember,
              })
            }
            
            if (code === 'SUCCESS') {
                console.log(data)
                const nowPage = surveyItem.pages.findIndex((el: any) => {
                return el.pageId === data.page.pageId
                }) //페이징 처리

                setTotalPage(surveyItem.pages.length)
                setPage(nowPage)

                // const reverseData = data.page.questions.reverse();

                // console.log(reverseData);
                setQuestionList([...data.page.questions])
                setQuestionInfo(data)
            } else {
                //설문지 반환 못했을경우
            }
            })
            .catch((err) => {
            const { errCode, errMsg } = err.response.data

            setAlertModal({
                alert: true,
                errorCode: errCode,
                msg: errMsg,
            })
            })
        } catch (err) {
        //api 요청 오류
        console.log(err)
        }
    }

  /** 다음설문으로 이동 (답변등록) */
  const moveNextQuestionHandler = async () => {
    try {
      //예외처리 flag
      let exceptionCheck = false

      /** 질문 미답변 예외처리 */
      try {
        questionList.forEach((item) => {
          const answerInfo = answerList.find(
            (el) => el.questionId === item.questionId
          )
          if (item.mandatoryYn !== 'N') {
            if (!answerInfo) {
              //답변선택을 안했을때
              //   console.log(item)
              showToast('답변 필수 질문입니다. 질문에 답해주세요.')
              exceptionCheck = true
              throw new Error('answer Error')
            } else {
            if (answerInfo.value && answerInfo.value === '') {
                //답변을 선택한적이 있지만 해제했을때
                showToast('답변 필수 질문입니다. 질문에 답해주세요.')
                exceptionCheck = true
                throw new Error('답변 필수 질문입니다. 질문에 답해주세요.')
              } else if (answerInfo.answers) {
                if (answerInfo.answers.length === 0) {
                  showToast('답변 필수 질문입니다. 질문에 답해주세요.')
                  exceptionCheck = true
                  throw new Error('answer error')
                } else {
                  answerInfo.answers.forEach((answerItem) => {
                    if (answerItem.value === '') {
                      showToast('답변 필수 질문입니다. 질문에 답해주세요.')
                      exceptionCheck = true
                      throw new Error('answer error')
                    }
                  })
                }
              }
            }
          }
        })
      } catch (e) {
        // showToast(e);
      }

      if (!exceptionCheck) {
        let postArray: AnswerListType[] = []
        answerList.map((item, index) => {
          //현재 페이지에있는 질문 답변만으로 구성하게 가공
          if (questionList.find((el) => el.questionId === item.questionId)) {
            postArray.push(item)
          }
        })

        const params = {
          postData: {
            questionAnswers: postArray,
          },
          headConfig: {
            memberId: memberId,
            Authorization: `Bearer ${token}`,
            refreshToken: `Bearer ${refreshToken}`,
          },
        }
        console.log(params);

        await nextQuestionMutation
          .mutateAsync(params)
            .then((res) => {
                const { code,msg, data, refreshInfo } = res; //refresh를 했을때

                console.log(code, msg, data);
                if (refreshInfo) { 
                    updateUserInfo({
                      accessTime: new Date(),
                      memberId: refreshInfo.memberId,
                      token: refreshInfo.tokenInfo.accessToken,
                      refreshToken: refreshInfo.tokenInfo.refreshToken,
                      isNewMember: refreshInfo.isNewMember,
                    })
                }

                if (code === 'SUCCESS') {
                const { action, subData } = data

                if (action === 'move') {
                  if (subData.pageurl.includes('finish')) {
                    navigation('/SurveyResult', { state: { pageurl: subData.pageurl } });
                    // if (surveyItem.type === 'SURVEY') {
                    //     navigation('/SurveyResult', {
                    //     state: { pageurl: subData.pageurl },
                    //     })
                    // } else {
                    //     navigation(
                    //     `/SurveyQuickResult?surveyId=${surveyItem.surveyId}`
                    //     )
                    // }
                    } else {
                    navigation(`/SurveyProgress?pageurl=${subData.pageurl}`, {
                        state: { surveyItem: surveyItem },
                    })
                    }
                }
                } else {
                console.log(msg, data, code)
                }
            })
            .catch((err) => {
                console.log(err);
                const { errCode, errMsg } = err.response.data

                setAlertModal({
                alert: true,
                errorCode: errCode,
                msg: errMsg,
                })
            })
        }
        } catch (err) {
        console.log(err)
        }
    }

  React.useEffect(() => {
    const proPer = (page / totalPage) * 100

    setSurveyProgress(Math.round(proPer))
  }, [page, totalPage])

  React.useEffect(() => {
    //초기 surveyId및 pageId 설정
    if (pageurl !== '' && pageurl) {
      setPageUrl(pageurl)
    }
  }, [pageurl])

  React.useEffect(() => {
    //질문지 받아오기.

    if (pageUrl !== '' && memberId !== '') {
      getSurveyQuetionHandler()
    }
  }, [pageUrl, memberId])

  React.useEffect(() => {
    // console.log(answerList)
  }, [answerList])

  return (
    <S.CustomDiv>
      <AlertModal
        isOpen={alertModal.alert}
        msg={alertModal.msg}
        errorCode={alertModal.errorCode}
        close={() => {
          setAlertModal({ alert: false, msg: '', errorCode: '' })
        }}
      />
      <S.ContainerXl>
        {/* <S.MarginCom mt={2} />
                <S.CustomFlex
                    bgcolor={colors.SUB_BACKGROUND_COLOR_PINK}
                    br={1.5}
                    pt={1} pb={1} pl={2} pr={2}
                    flex_direction='row'
                    align_items='center'

                >
                    <S.CustomImg 
                        width={2}
                        src={require('../../assets/img/ico_notice_red.png')}
                    />
                    <S.CustomPText 
                        fs={1.3}
                        fc={colors.RED_COLOR}
                        ml={1}
                    >
                        설문 조사를 위한 검증이 필요합니다.
                    </S.CustomPText>
                </S.CustomFlex> */}
        <S.MarginCom mb={3} />
        <ProgressBar progress={surveyProgress} />

        <S.CustomDiv pt={3} pb={2}>
          {questionList.length > 0 &&
            questionList.map((item, index) => {
              return (
                <S.CustomDiv key={index} mb={3}>
                  <S.CustomPText
                    fs={1.7}
                    fc={colors.SKY_BLUE_COLOR}
                    fw={'Bold'}
                    mb={1}
                  >
                    Q {item.number}
                  </S.CustomPText>

                  <S.CustomPText
                    fs={1.8}
                    fw={'Medium'}
                    fc={colors.FONT_COLOR1}
                    mb={2}
                  >
                    {item.title}
                  </S.CustomPText>
                  {item.choices.map((choiceItem, index) => {
                    return (
                      <S.CustomDiv key={index}>
                        {choiceItem.type !== 'IMAGE' && (
                          <S.CustomDiv>
                            {item.type === 'TEXT' && (
                              <SurveyTextInput
                                inputText={
                                  answerList.filter(
                                    (el) => el.questionId === item.questionId
                                  )[0]
                                    ? answerList.filter(
                                        (el) =>
                                          el.questionId === item.questionId
                                      )[0].value
                                    : ''
                                }
                                setInputText={setInputSurvey}
                                type={choiceItem.type}
                                questionId={item.questionId}
                                choiceId={choiceItem.choiceId}
                                maxLength={choiceItem.maxLength}
                              />
                            )}
                            {(item.choiceDisplayOption === 'FIXED' ||
                              item.choiceDisplayOption === null) &&
                              (item.type === 'SEL1' ||
                                item.type === 'SELN') && (
                                <SurveyRadioCustom
                                  radioArray={item.choices}
                                  radioValue={choiceItem.value}
                                  selValue={
                                    item.type === 'SELN'
                                      ? answerList.filter(
                                          (el) =>
                                            el.questionId === item.questionId
                                        )[0]
                                        ? answerList
                                            .filter(
                                              (el) =>
                                                el.questionId ===
                                                item.questionId
                                            )[0]
                                            .answers?.filter(
                                              (el) =>
                                                el.choiceId ===
                                                choiceItem.choiceId
                                            )[0]?.choiceId || ''
                                        : ''
                                      : answerList.filter(
                                          (el) =>
                                            el.questionId === item.questionId
                                        )[0]
                                      ? answerList.filter(
                                          (el) =>
                                            el.questionId === item.questionId
                                        )[0].value
                                      : ''
                                  }
                                  answerList={
                                    item.type === 'SELN'
                                      ? answerList.filter(
                                          (el) =>
                                            el.questionId === item.questionId
                                        )[0]
                                        ? answerList.filter(
                                            (el) =>
                                              el.questionId === item.questionId
                                          )[0].answers
                                        : []
                                      : undefined
                                  }
                                  setSelSurvey={setSelSurvey}
                                  maxSelect={item.maxSelect}
                                  questionId={item.questionId}
                                  title={choiceItem.title}
                                  isMultiple={item.type === 'SELN'}
                                  choiceId={choiceItem.choiceId}
                                  maxLength={choiceItem.maxLength}
                                  cIndex={index}
                                  type={choiceItem.type}
                                />
                              )}
                            {item.type === 'RANKING' && (
                              <SurveyRankingList
                                radioArray={item.choices}
                                radioValue={choiceItem.value}
                                selValue={
                                  answerList.filter(
                                    (el) => el.questionId === item.questionId
                                  )[0]
                                    ? answerList
                                        .filter(
                                          (el) =>
                                            el.questionId === item.questionId
                                        )[0]
                                        .answers?.filter(
                                          (el) =>
                                            el.choiceId === choiceItem.choiceId
                                        )[0]?.value || ''
                                    : ''
                                }
                                answerList={
                                  answerList.filter(
                                    (el) => el.questionId === item.questionId
                                  )[0]
                                    ? answerList.filter(
                                        (el) =>
                                          el.questionId === item.questionId
                                      )[0].answers
                                    : []
                                }
                                setSelSurvey={setSelSurvey}
                                maxSelect={item.maxSelect}
                                questionId={item.questionId}
                                title={choiceItem.title}
                                choiceId={choiceItem.choiceId}
                              />
                            )}
                          </S.CustomDiv>
                        )}
                      </S.CustomDiv>
                    )
                  })}
                  {item.type === 'DROPDOWN' && (
                    <SurveySelectBox
                      selectList={item.choices}
                      answerList={
                        answerList.filter(
                          (el) => el.questionId === item.questionId
                        )[0]
                          ? answerList.filter(
                              (el) => el.questionId === item.questionId
                            )[0].answers
                          : []
                      }
                      selectListHandler={dropBoxHandler}
                      quetionItemInfo={item}
                    />
                  )}
                  {item.type === 'INDEX_STAR' && (
                    <S.CustomFlex
                      flex_direction={'row'}
                      // align_items='center'
                      justify_content="space-between"
                      style={{ gap: '1rem' }}
                    >
                      {item.choices.map((choiceItem, index) => {
                        return (
                          <SurveyStarSelect
                            key={index}
                            selStarValue={
                              answerList.filter(
                                (el) => el.questionId === item.questionId
                              )[0]
                                ? answerList.filter(
                                    (el) => el.questionId === item.questionId
                                  )[0].value
                                : ''
                            }
                            selectStarHandler={setInputSurvey}
                            starValue={choiceItem.value}
                            questionId={item.questionId}
                            title={choiceItem.title}
                            choiceId={choiceItem.choiceId}
                          />
                        )
                      })}
                    </S.CustomFlex>
                  )}

                  {item.choiceDisplayOption === 'FLEX' &&
                    (item.type === 'SELN' || item.type === 'SEL1') && (
                      <S.CustomDiv>
                        {item.img !== '' && (
                          <S.CustomImg
                            width={'100%'}
                            src={`${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_HOST_VER}${item.img}`}
                            mb={1}
                            br={1}
                          />
                        )}
                        <GridWrapper>
                          {item.choices.map((choiceItem, cIndex) => {
                            return (
                              <SurveyRadioCustom
                                key={cIndex}
                                cIndex={cIndex}
                                radioArray={item.choices}
                                radioValue={choiceItem.value}
                                selValue={
                                  item.type === 'SELN'
                                    ? answerList.filter(
                                        (el) =>
                                          el.questionId === item.questionId
                                      )[0]
                                      ? answerList
                                          .filter(
                                            (el) =>
                                              el.questionId === item.questionId
                                          )[0]
                                          .answers?.filter(
                                            (el) =>
                                              el.choiceId ===
                                              choiceItem.choiceId
                                          )[0]?.choiceId || ''
                                      : ''
                                    : answerList.filter(
                                        (el) =>
                                          el.questionId === item.questionId
                                      )[0]
                                    ? answerList.filter(
                                        (el) =>
                                          el.questionId === item.questionId
                                      )[0].value
                                    : ''
                                }
                                answerList={
                                  item.type === 'SELN'
                                    ? answerList.filter(
                                        (el) =>
                                          el.questionId === item.questionId
                                      )[0]
                                      ? answerList.filter(
                                          (el) =>
                                            el.questionId === item.questionId
                                        )[0].answers
                                      : []
                                    : undefined
                                }
                                setSelSurvey={setSelSurvey}
                                maxSelect={item.maxSelect}
                                questionId={item.questionId}
                                title={choiceItem.title}
                                isMultiple={item.type === 'SELN'}
                                choiceId={choiceItem.choiceId}
                                maxLength={choiceItem.maxLength}
                                type={choiceItem.type}
                              />
                            )
                          })}
                        </GridWrapper>
                      </S.CustomDiv>
                    )}
                  {(item.type === 'SELN' || item.type === 'SEL1') &&
                    item.choices[0].type === 'IMAGE' && (
                      <S.CustomDiv>
                        <ImageGridWrapper>
                          {item.choices.map((choiceItem, cIndex) => {
                            return (
                              <SurvayImgSoloSel
                                key={cIndex}
                                choiceItem={choiceItem}
                                choiceArray={item.choices}
                                selValue={
                                  answerList.filter(
                                    (el) => el.questionId === item.questionId
                                  )[0]
                                    ? answerList
                                        .filter(
                                          (el) =>
                                            el.questionId === item.questionId
                                        )[0]
                                        .answers?.filter(
                                          (el) =>
                                            el.choiceId === choiceItem.choiceId
                                        )[0]?.choiceId || ''
                                    : ''
                                }
                                questionId={item.questionId}
                                maxSelect={item.maxSelect}
                                selectImgHandler={setSelSurvey}
                                answerList={
                                  answerList.filter(
                                    (el) => el.questionId === item.questionId
                                  )[0]
                                    ? answerList.filter(
                                        (el) =>
                                          el.questionId === item.questionId
                                      )[0].answers
                                    : []
                                }
                              />
                            )
                          })}
                        </ImageGridWrapper>
                      </S.CustomDiv>
                    )}

                  {item.type === 'INDEX_NUM' && (
                    <SurveyDotSelect
                      selDotValue={
                        answerList.filter(
                          (el) => el.questionId === item.questionId
                        )[0]
                          ? answerList.filter(
                              (el) => el.questionId === item.questionId
                            )[0].value
                          : ''
                      }
                      selectDotHandler={setSelSurvey}
                      choiceList={item.choices}
                      questionId={item.questionId}
                      isLabel
                    />
                  )}
                </S.CustomDiv>
              )
            })}
        </S.CustomDiv>
      </S.ContainerXl>
      <S.MarginCom mb={8} />

      <S.CustomFlex
        position="fixed"
        bottom={0}
        flex_direction={'row'}
        width={'100%'}
        btw={0.1}
        btc={colors.BORDER_COLOR2}
      >
        <S.CustomFlex
          flex={1}
          pt={2.5}
          pb={2.5}
          pl={4}
          pr={4}
          justify_content={'center'}
          align_items={'center'}
          bgcolor={colors.WHITE}
          onClick={() => {
            navigation(-1)
          }}
        >
          <S.CustomPText fs={1.8} fw={'Bold'} fc={colors.FONT_COLOR3}>
            이전
          </S.CustomPText>
        </S.CustomFlex>
        <S.CustomFlex
          flex={2}
          pt={2.5}
          pb={2.5}
          pl={4}
          pr={4}
          justify_content={'center'}
          align_items={'center'}
          bgcolor={colors.MAIN_COLOR}
          onClick={() => {
            // navigation(`/SurveyProgress?pageurl=/surveys/survey-master-3/pages/page-6`)
            moveNextQuestionHandler()
          }}
          // onClick={()=>{
          //     if(page < totalPage){
          //         setPage(p=>p+1)
          //     }
          //     else{
          //         if(acc_type === 'quick_survey'){
          //             navigation('/SurveyQuickResult');
          //         }
          //         else{
          //             navigation('/SurveyResult');
          //         }
          //     }
          // }}
        >
          <S.CustomPText fs={1.8} fw={'Bold'} fc={colors.WHITE}>
            {/* {page < totalPage ? '다음' : '설문완료'} */}
            {questionInfo?.page.type === "QUESTION_SUBMIT" ? '제출하기' : '다음'}
          </S.CustomPText>
        </S.CustomFlex>
      </S.CustomFlex>
    </S.CustomDiv>
  )
}
