import React from 'react'
import * as S from '../../theme/style'
import Modal from 'react-modal'
import { IframeModalType } from '../ComponentCommonType'
import { colors } from '../../theme/color'

export const IframeModal = ({
  isOpen,
  onClose,
  iframeLink,
}: IframeModalType) => {
  return (
    <Modal
      isOpen={isOpen}
      style={{
        content: {
          width: '100%',
          height: '100%',
          inset: 0,
          padding: 0,
          borderRadius: 0,
          border: 'none',
        },
      }}
    >
      <S.CustomFlex width={'100%'} height={'100%'}>
        {/* <S.CustomFlex flex={8} justify_content="center" align_items="center">
          <S.CustomImg width={'100%'} src={iframeLink} object_fit="contain" />
        </S.CustomFlex> */}
        <iframe style={{ width: '100%', height: '100%' }} src={iframeLink} />
      </S.CustomFlex>
    </Modal>
  )
}
