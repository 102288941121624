import React from 'react';
import * as S from '../theme/style';
import { colors } from '../theme/color';
import { NumberComma } from '../utils/func';
import { pointList } from '../utils/trashData';

interface SurPointListItemType {
    index : number, //item index
    title : string, //적립 제목
    point : number // 포인트
    type : string //적립 타입
    date : string //적립 날짜
}

export const PointList = () => {

    const [surPointList, setSurPointList] = React.useState<SurPointListItemType[]>([]);

    const [page, setPage] = React.useState(1);
    const [totalPage, setTotalPage] = React.useState(5);

    function pageHandler(type : 'prev' | 'next'){
        if(type === 'prev'){
            if(page !== 1){
                setPage(p=>p-1);
            }
        }
        else{
            if(page !== totalPage){
                setPage(p=>p+1);
            }
        }
    }

    React.useEffect(()=>{
        const updateArrayByPage = () => {
            const pageSize = 10; // 한 페이지에 보여줄 아이템 개수
            const startIndex = (page - 1) * pageSize; // 현재 페이지의 시작 인덱스
            const endIndex = startIndex + pageSize; // 현재 페이지의 끝 인덱스
        
            // 기존 배열인 TrashSurveyMainList에서 해당 페이지에 해당하는 일부를 선택
            const selectedItems = pointList().slice(startIndex, endIndex);
            if(page === 1){
                setTotalPage(Math.ceil(pointList().length/pageSize));
            }
                setSurPointList([...selectedItems]);

        };
    
        updateArrayByPage();
    },[page])

    return(
        <S.CustomDiv>
            <S.ContainerXl>
                <S.MarginCom mt={2}/>
                <S.CustomPText
                    fs={1.8}
                    fw={'Bold'}
                    fc={colors.FONT_COLOR1}
                >
                    설문 포인트 내역
                </S.CustomPText>

                <S.CustomDiv
                    width={'100%'}
                    mt={2}
                >
                    <S.CustomImg
                        width={'100%'}
                        src={require('../assets/img/point_box.png')}
                    />
                    <S.CustomDiv
                        position={'absolute'}
                        pl={2} pr={2}
                        style={{top:'50%',transform:'translate(0,-50%)'}}
                    >
                    <S.CustomPText
                            fs={1.5}
                            fc={colors.WHITE}
                        >
                            나의 현재 포인트
                        </S.CustomPText>
                        <S.MarginCom mt={1} />
                        <S.CustomPText
                            fs={3.8}
                            fc={colors.WHITE}
                            fw={'Bold'}
                        >
                            {NumberComma(15100)}P
                        </S.CustomPText>

                    </S.CustomDiv>
                </S.CustomDiv>
            </S.ContainerXl>

            <S.CustomDiv mt={2}>
                {surPointList.map((item,index) => {
                    return(
                        <S.CustomDiv
                            bbw={0.1}
                            bbc={colors.BORDER_COLOR2}
                            pt={2} pb={2} pl={4} pr={4}
                            key={index}
                        >
                            <S.CustomFlex
                                flex_direction='row'
                                justify_content='space-between'
                                align_items='center'
                            >   
                                <S.CustomDiv flex={8} style={{textAlign:'left'}}>
                                    <S.CustomSpanText
                                    text_align='left'
                                        fs={1.6}
                                        fc={colors.FONT_COLOR1}
                                    >
                                        {item.title}
                                    </S.CustomSpanText>
                                </S.CustomDiv>
                                <S.CustomDiv flex={2} style={{textAlign:'right'}}>
                                    <S.CustomSpanText
                                        fs={1.6}
                                        fc={colors.WHITE_RED_COLOR}
                                        fw={'Bold'}
                                    >
                                        + {item.point}p
                                    </S.CustomSpanText>
                                </S.CustomDiv>
                            </S.CustomFlex>
                            <S.CustomFlex
                                flex_direction='row'
                                justify_content='space-between'
                                align_items='center'
                                mt={1}
                            >
                                <S.CustomSpanText
                                    fs={1.3}
                                    fc={colors.FONT_COLOR3}
                                >
                                    {item.date}
                                </S.CustomSpanText>
                                <S.CustomSpanText
                                    ml={1}

                                    fs={1.3}
                                    fc={colors.FONT_COLOR3}
                                >
                                    {item.type}
                                </S.CustomSpanText>
                            </S.CustomFlex>
                        </S.CustomDiv>
                    )
                })}
                
            </S.CustomDiv>

            <S.CustomFlex
                mt={4}
                mb={4}
                align_items='center'
            >
                <S.CustomFlex
                    width={'40%'}
                    flex_direction='row'
                    justify_content='space-between'
                    align_items='center'
                >
                    <S.CustomImg 
                        width={4}
                        src={require('../assets/img/btn_prev.png')}
                        onClick={()=>{pageHandler('prev')}}
                    />
                    <S.CustomPText
                        fs={1.3}
                    >
                        <S.CustomSpanText
                            fc={colors.MAIN_COLOR}
                        >
                            {page}&nbsp;
                        </S.CustomSpanText>
                        <S.CustomSpanText
                            fc={colors.FONT_COLOR2}
                        >
                            / {totalPage}
                        </S.CustomSpanText>
                    </S.CustomPText>
                    <S.CustomImg 
                        width={4}
                        src={require('../assets/img/btn_next.png')}
                        onClick={()=>{pageHandler('next')}}

                    />
                </S.CustomFlex>
            </S.CustomFlex>
        </S.CustomDiv>
    )
}
