import React from 'react';
import * as S from '../../theme/style';
import { colors } from '../../theme/color';
import { styled } from 'styled-components';
import { Range, getTrackBackground } from 'react-range';

interface ProgressBarType {
    progress : number;
}


export const ProgressBar = ({ //설문 진행도
    progress
}:ProgressBarType) => {

    return(
        <>
            <S.MarginCom mt={2} />
            <Range
                values={[progress=== 0 ? progress+3 : progress === 100 ? progress-3 : progress]}
                min={0}
                max={100}
                onChange={(values) => {}}
                disabled={true}
                renderTrack={({ props, children }) => (
                    <div
                onMouseDown={props.onMouseDown}
                onTouchStart={props.onTouchStart}
                style={{
                    ...props.style,
                    display: "flex",
                    width: "100%"
                }}
                >
                    <div
                        ref={props.ref}
                        style={{
                        height: "1rem",
                        width: "100%",
                        borderRadius: "4px",
                        background: getTrackBackground({
                            values: [progress],
                            colors: [colors.SKY_BLUE_COLOR, colors.SUB_BACKGROUND_COLOR6],
                            min: 0,
                            max: 100
                        }),
                        alignSelf: "center"
                        }}
                    >
                        {children}
                    </div>
                </div>
                )}
                renderThumb={({ props }) => (
                    <S.CustomFlex
                        {...props}
                        pl={1} pr={1} pt={0.5} pb={0.5}
                        mr={2} ml={2}
                        br={15}
                        style={{...props.style,transition:'left 0.5s'}}
                        bgcolor={colors.SKY_BLUE_COLOR}
                        justify_content='center'
                    >
                        <S.CustomPText
                            fs={1.3}
                            fc={colors.WHITE}
                        >
                            {progress}%
                        </S.CustomPText>

                    </S.CustomFlex>
                )}
            />

        </>
    )
}