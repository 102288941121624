import { Container } from './ToastStyle';
import { toast, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const toastOptions: ToastOptions = {
  position: 'bottom-center',
  autoClose: 2000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  pauseOnFocusLoss: true,
  closeButton: false,
};

export function showToast(message : string) {
        toast.error(message, {
          ...toastOptions,
          icon: <img src={require('../../assets/img/ico_check2.png')} alt="error" />,
        });
  }
  
  export default function Toast() {
    return <Container />;
  }