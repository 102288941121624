import { styled } from "styled-components";
import { CustomFlex } from "../theme/style";
import * as S from '../theme/style';
import { DotSelectorWrapperType } from "./ComponentCommonType";
import { colors } from "../theme/color";
interface DonutBackType {
    width : number,
    height : number,
    frontColor : string,
    backColor : string,
    donutper : number,
} 

interface DonutFrontType {
    width : number,
    height : number,
}

const DonutBack = styled(CustomFlex)<DonutBackType>` //도넛그래프 뒷배경
    width: ${({width}) => width+'rem'};
    height: ${({height}) => height+'rem'};
    margin: 0 auto;
    border-radius: 50%;
    position: relative;
    text-align: center;

    @property --tranDonutPer{
        syntax: '<percentage>'; /* its type */
        inherits: false;
        initial-value: ${({donutper}) => `${donutper}%`}; /* the initial value */
    }
    transition: --tranDonutPer 0.5s;
    /* background: conic-gradient(from 0deg at center center, # 0% , #FFFF00); */
    background : ${({frontColor,backColor,donutper}) => `conic-gradient(from 0deg at center center, ${frontColor} , ${backColor})`};
`

const DonutFront = styled(CustomFlex)<DonutFrontType>` //도넛그래프 앞배경
    position:absolute;
    width:${({width}) => width+'rem'};
    height:${({height}) => height+'rem'};
    border-radius: 50%;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);

    background-color:white;
`

const GraphContainer = styled.article` //도넛그래프 컨테이너
    position: relative;
    width:'100%';
    width:6.5rem;
    height:6.5rem;
    max-height: 15rem;
    border-radius: 100%;
    /* background: white; */
`;

const TextContainer = styled.div` //도넛그래프 가운대에 들어가는 글자 container
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 5rem;
    height : 5rem;
    border-radius: 100%;
    background-color:'blue';

    display:flex;
    justify-content: center;
    align-items : center;

    p:first-child {
        white-space: nowrap;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 24px;
        text-align: center;
        letter-spacing: -0.5px;

        color: #000000;
    }

    p:last-child {
        white-space: nowrap;
        font-size: 0.875rem;
        line-height: 24px;
        text-align: center;
        letter-spacing: -0.5px;

        color: #000000;
    }
`;

const DotSelectorWrapper = styled(S.CustomFlex)<DotSelectorWrapperType>`
    position:relative;
    overflow: visible;
    height:5rem;
    ${({content,absolute_left,asolute_right,ab_text_align}) => content && `
        &::after{
            content:'${content}';
            position:absolute;
            top:3.5rem;
            width:5rem;
            ${ab_text_align && `text-align : ${ab_text_align};`}
            ${asolute_right !== undefined ? `right : ${asolute_right}rem;` : ''}
            ${absolute_left !== undefined ? `left : ${absolute_left}rem;` : ''}
            font-size:1.2rem;
            font-family:'Light';
            color:${colors.FONT_COLOR2};
        }
    `}
`

const BarGraphFront = styled(S.CustomDiv)` //Vertical Bar Graph 애니메이션
    ${({ height }) => height && `
        height:${height}%;
        max-height: ${height}%;
        overflow: hidden;
        transition: max-height 0.8s;
    `}
`
const VerticalGraphFront = styled(S.CustomDiv)` //Vertical Bar Graph 애니메이션
    ${({ width }) => width && `
        height:${width}%;
        max-height: ${width}%;
        overflow: hidden;
        transition: max-width 0.8s;
    `}
`


export {
    DonutBack,
    DonutFront,
    GraphContainer,
    TextContainer,
    DotSelectorWrapper,
    BarGraphFront,
    VerticalGraphFront,
}